// src/pages/NotificationManagementPage.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, Fab, Tooltip, Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

import {
  getNotificationCategories,
  getNotificationMessageList,
  deleteNotification,
  getNotificationMessageById,

} from '../services/notificationService';

import SendNotificationDialog from '../components/notificationManagementPage/SendNotificationDialog';
import NotificationFormDialog from '../components/notificationManagementPage/NotificationFormDialog';
import NotificationsToolbar from '../components/notificationManagementPage/NotificationsToolbar';
import NotificationsGrid from '../components/notificationManagementPage/NotificationsGrid';
import PaginationControls from '../components/notificationManagementPage/PaginationControls';

const NotificationManagementPage = () => {
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  // State for Create/Edit Dialog
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [formMode, setFormMode] = useState('create'); // 'create' or 'edit'
  const [initialFormData, setInitialFormData] = useState(null);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalNotifications, setTotalNotifications] = useState(0);

  // Loading States
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  // Error States
  const [errorNotifications, setErrorNotifications] = useState(null);
  const [errorCategories, setErrorCategories] = useState(null);

  // Debounced Search Term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Preview Dialog States
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState('');

  // Fetch categories when component mounts
  useEffect(() => {
    fetchCategories();
  }, []);

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch notifications when component mounts or pagination/filtering/search changes
  useEffect(() => {
    fetchNotifications(currentPage, pageSize, selectedCategories, debouncedSearchTerm);
  }, [currentPage, pageSize, selectedCategories, debouncedSearchTerm]);

  const fetchCategories = async () => {
    setLoadingCategories(true);
    setErrorCategories(null);
    try {
      const response = await getNotificationCategories();
      setCategories(response);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setErrorCategories('Failed to load categories.');
    } finally {
      setLoadingCategories(false);
    }
  };

  const fetchNotifications = useCallback(async (page, pageSize, categoryIds, searchQuery) => {
    setLoadingNotifications(true);
    setErrorNotifications(null);
    try {
      const response = await getNotificationMessageList(page, pageSize, categoryIds, searchQuery);
      setNotificationList(response.rows || []);
      setTotalNotifications(response.count || 0);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setErrorNotifications('Failed to load notifications.');
    } finally {
      setLoadingNotifications(false);
    }
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteNotification(id);
      const newTotal = totalNotifications - 1;
      const newTotalPages = Math.ceil(newTotal / pageSize);
      if (currentPage > newTotalPages && newTotalPages > 0) {
        setCurrentPage(newTotalPages);
      } else {
        fetchNotifications(currentPage, pageSize, selectedCategories, debouncedSearchTerm);
      }
      setTotalNotifications(newTotal);
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleEditClick = async (notification) => {
    try {
      const fetchedNotification = await getNotificationMessageById(notification.id);
      setFormMode('edit');
      setInitialFormData(fetchedNotification);
      setFormDialogOpen(true);
    } catch (error) {
      console.error('Error fetching notification details:', error);
    }
  };

  const handleCreateClick = () => {
    setFormMode('create');
    setInitialFormData(null);
    setFormDialogOpen(true);
  };

  const handleFormDialogClose = () => {
    setFormDialogOpen(false);
  };

  const handleSend = (notification) => {
    setSelectedNotification(notification);
    setSendDialogOpen(true);
  };

  const handleSendDialogClose = () => {
    setSendDialogOpen(false);
    setSelectedNotification(null);
  };

  const handlePreview = async (id) => {
    try {
      const notification = await getNotificationMessageById(id);
      setPreviewContent(notification.html_message);
      setPreviewDialogOpen(true);
    } catch (error) {
      console.error('Error fetching notification content:', error);
    }
  };

  const handlePreviewClose = () => {
    setPreviewDialogOpen(false);
    setPreviewContent('');
  };

  // Handle Category Selection
  const handleCategoryToggle = (categoryId) => {
    setCurrentPage(1);
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId)
        : [...prevSelected, categoryId]
    );
  };

  // Convert binary icon data to base64
  const convertIconToBase64 = (icon) => {
    if (!icon || !icon.data || icon.data.length === 0) return '';
    const uint8Array = new Uint8Array(icon.data);
    let binary = '';
    uint8Array.forEach((byte) => {
      binary += String.fromCharCode(byte);
    });
    return `data:image/webp;base64,${btoa(binary)}`;
  };

  // Memoize the converted icons
  const notificationListWithIcons = useMemo(() => {
    return notificationList.map((notification) => ({
      ...notification,
      base64Icon: convertIconToBase64(notification.icon),
    }));
  }, [notificationList]);

  // Calculate total pages for pagination
  const totalPages = Math.ceil(totalNotifications / pageSize);

  const handleSearch = () => {
    setDebouncedSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  return (
    <Box p={2} position="relative" minHeight="100vh" sx={
      {
        overflowY: 'auto',
      }
    }>
      <Typography variant="h4" gutterBottom>
        Notification Management
      </Typography>

      {/* Floating Action Button for creating new notification */}
      <Tooltip title="Create New Notification">
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleCreateClick}
          sx={{
            position: 'fixed',
            bottom: 75,
            right: 32,
            zIndex: 1000,
          }}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      {/* Toolbar */}
      <NotificationsToolbar
        categories={categories}
        selectedCategories={selectedCategories}
        loadingCategories={loadingCategories}
        errorCategories={errorCategories}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        setDebouncedSearchTerm={setDebouncedSearchTerm}
        setCurrentPage={setCurrentPage}
        handleCategoryToggle={handleCategoryToggle}
        navigate={navigate}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      {/* Notifications Grid */}
      <Grid container spacing={2}>
        <NotificationsGrid
          loadingNotifications={loadingNotifications}
          errorNotifications={errorNotifications}
          notificationList={notificationListWithIcons}
          handleEditClick={handleEditClick}
          handleDelete={handleDelete}
          handleSend={handleSend}
          handlePreview={handlePreview}
          fetchNotifications={() =>
            fetchNotifications(currentPage, pageSize, selectedCategories, debouncedSearchTerm)
          }
        />
      </Grid>

      {/* Pagination Controls */}
      <PaginationControls
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {/* Send Notification Dialog */}
      <SendNotificationDialog
        open={sendDialogOpen}
        onClose={handleSendDialogClose}
        notification={selectedNotification}
      />

      {/* Create/Edit Notification Dialog */}
      <NotificationFormDialog
        open={formDialogOpen}
        onClose={handleFormDialogClose}
        mode={formMode}
        initialData={initialFormData}
        onSuccess={() =>
          fetchNotifications(currentPage, pageSize, selectedCategories, debouncedSearchTerm)
        }
      />

      {/* Preview Dialog */}
      <Dialog open={previewDialogOpen} onClose={handlePreviewClose} fullWidth maxWidth="md">
        <DialogTitle>Notification Preview</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: previewContent }}></div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NotificationManagementPage;