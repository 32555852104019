// src/services/qridService.js

import axios from 'axios';

async function generateQRid() {
    try {
        const response = await axios.get('/api/qrid/generate');
        return response.data.base64ID;
    } catch (error) {
        console.error('Error generating QR ID:', error);
        throw error;
    }
}

async function verifyQRid(base64ID) {
    try {
        const response = await axios.post('/api/qrid/verify', { base64ID });
        return response.data.result;
    } catch (error) {
        console.error('Error verifying QR ID:', error);
        throw error;
    }
}

const qridService = {
    generateQRid,
    verifyQRid
};

export default qridService;