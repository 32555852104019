// src/components/NotificationFormDialog.js
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    AppBar,
    Toolbar as MuiToolbar,
    IconButton,
    Typography,
    Button,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slide,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CodeIcon from '@mui/icons-material/Code';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { v4 as uuidv4 } from 'uuid';
import { FileSelectionPopup } from '../fileManagement';

// Import necessary service functions
import {
    getNotificationCategories,
    createNotificationMessage,
    updateNotification
} from '../../services/notificationService';

// Utility function to resize and convert image to base64 WebP
const resizeAndConvertImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
            image.src = e.target.result;
        };

        image.onload = () => {
            const canvas = document.createElement('canvas');
            let { width, height } = image;

            // Calculate new dimensions while maintaining aspect ratio
            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            resolve(reader.result);
                        };
                        reader.readAsDataURL(blob);
                    } else {
                        reject(new Error('Canvas is empty'));
                    }
                },
                'image/webp',
                0.8 // Quality parameter (0.0 - 1.0)
            );
        };

        image.onerror = () => {
            reject(new Error('Failed to load image'));
        };

        reader.readAsDataURL(file);
    });
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const NotificationFormDialog = ({
    open,
    onClose,
    mode, // 'create' or 'edit'
    initialData, // Notification object for edit mode
    onSuccess // Callback after successful create/update
}) => {
    const quillRef = useRef(null);
    const [formData, setFormData] = useState({
        title: '',
        short_message: '',
        html_message: '',
        icon: '',
        category_id: ''
    });
    const [categories, setCategories] = useState([]);
    const [message, setMessage] = useState('');
    const [iconFile, setIconFile] = useState(null);
    const [iconPreview, setIconPreview] = useState('');
    const [editorMode, setEditorMode] = useState('rich');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileSelectionOpen, setFileSelectionOpen] = useState(false);
    const [fileSelectionContext, setFileSelectionContext] = useState(null);

    const imageHandler = useCallback(() => {
        // Set context and open file selection for Quill image insertion
        setFileSelectionContext('quillImage');
        setFileSelectionOpen(true);
    }, []);

    // Handler for upload icon click
    const handleIconUploadClick = () => {
        // Set context and open file selection for icon upload
        setFileSelectionContext('iconUpload');
        setFileSelectionOpen(true);
    };

    const handleFileSelect = async (file) => {
        if (fileSelectionContext === 'quillImage') {
            // Insert image into Quill editor
            const url = `https://satitchula.app/api/storage/file/${file.path}`;
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, 'image', url);
        } else if (fileSelectionContext === 'iconUpload') {
            // Set the selected file as the notification icon
            try {
                const resizedBase64 = await resizeAndConvertImage(file, 128, 128);
                setFormData((prev) => ({
                    ...prev,
                    icon: resizedBase64,
                }));
                setIconPreview(resizedBase64);
                setIconFile(file);
            } catch (error) {
                console.error('Error processing image:', error);
                setMessage('Failed to process the uploaded image.');
            }
        }
        // Reset context and close file selection
        setFileSelectionContext(null);
        setFileSelectionOpen(false);
    };

    useEffect(() => {
        // Fetch categories when the component mounts
        const fetchCategories = async () => {
            try {
                const data = await getNotificationCategories();
                setCategories(data);
            } catch (error) {
                console.error("Error fetching notification categories:", error);
                setMessage('Error fetching notification categories.');
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (mode === 'edit' && initialData) {
            setFormData({
                title: initialData.title || '',
                short_message: initialData.short_message || '',
                html_message: initialData.html_message || '',
                icon: initialData.icon ? `data:image/webp;base64,${btoa(String.fromCharCode(...new Uint8Array(initialData.icon.data)))}`
                    : '',
                category_id: initialData.category_id || ''
            });
            if (initialData.icon && initialData.icon.data) {
                const base64Icon = `data:image/webp;base64,${btoa(String.fromCharCode(...new Uint8Array(initialData.icon.data)))}`;
                setIconPreview(base64Icon);
            }
        } else if (mode === 'create') {
            setFormData({
                title: '',
                short_message: '',
                html_message: '',
                icon: '',
                category_id: ''
            });
            setIconPreview('');
            setIconFile(null);
            setMessage('');
        }
    }, [mode, initialData, open]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleHtmlMessageChange = (value) => {
        setFormData(prev => ({
            ...prev,
            html_message: value
        }));
    };

    const handleIconChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const resizedBase64 = await resizeAndConvertImage(file, 128, 128);
                setFormData(prev => ({
                    ...prev,
                    icon: resizedBase64
                }));
                setIconPreview(resizedBase64);
                setIconFile(file);
            } catch (error) {
                console.error('Error processing image:', error);
                setMessage('Failed to process the uploaded image.');
            }
        }
    };

    const handleRemoveIcon = () => {
        setFormData(prev => ({
            ...prev,
            icon: ''
        }));
        setIconPreview('');
        setIconFile(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        // Validation
        if (!formData.title.trim() || !formData.short_message.trim() || !formData.html_message.trim() || !formData.category_id) {
            setMessage('Please fill in all required fields.');
            return;
        }

        try {
            if (mode === 'create') {
                await createNotificationMessage(formData);
                setMessage('Notification created successfully!');
            } else if (mode === 'edit' && initialData) {
                await updateNotification(initialData.id, formData);
                setMessage('Notification updated successfully!');
            }
            onSuccess && onSuccess();
            onClose();
        } catch (error) {
            console.error('Error saving notification:', error);
            setMessage('Error saving notification. Please try again.');
        }
    };

    const handleEditorModeChange = (event, newMode) => {
        if (newMode !== null) {
            setEditorMode(newMode);
        }
    };

        const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
                ['clean']
            ],
            handlers: {
                image: imageHandler
            }
        }
    }), [imageHandler]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <MuiToolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {mode === 'create' ? 'Create a Notification' : 'Edit Notification'}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSubmit}>
                        {mode === 'create' ? 'Create' : 'Save'}
                    </Button>
                </MuiToolbar>
            </AppBar>
            <Box p={3} maxWidth="md" mx="auto">
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                    />
                    <TextField
                        label="Short Message"
                        name="short_message"
                        value={formData.short_message}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                    />

                    <Box display="flex" alignItems="center" mb={2}>
                        <ToggleButtonGroup
                            value={editorMode}
                            exclusive
                            onChange={handleEditorModeChange}
                            aria-label="editor mode"
                            size="small"
                        >
                            <ToggleButton value="rich" aria-label="Rich Text Editor">
                                <TextFieldsIcon /> Rich Text
                            </ToggleButton>
                            <ToggleButton value="html" aria-label="HTML Editor">
                                <CodeIcon /> HTML
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    {editorMode === 'rich' ? (
                        <ReactQuill
                            ref={quillRef}
                            value={formData.html_message}
                            onChange={handleHtmlMessageChange}
                            modules={modules}
                            formats={[
                                'header',
                                'bold', 'italic', 'underline', 'strike',
                                'list', 'bullet',
                                'link', 'image', 'video'
                            ]}
                            placeholder="Compose your notification..."
                            style={{ marginBottom: '16px', height: '300px' }}
                        />
                    ) : (
                        <TextField
                            label="HTML Message"
                            name="html_message"
                            value={formData.html_message}
                            onChange={handleHtmlMessageChange}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            required
                            multiline
                            rows={10}
                        />
                    )}

                    <Box mt={2} mb={2} sx={{
                        paddingTop: '40px',
                    }}>
                        <Button variant="contained" onClick={handleIconUploadClick}>
                            Upload Icon
                        </Button>
                        {iconPreview && (
                            <Box mt={2}>
                                <Typography variant="subtitle1">Icon Preview:</Typography>
                                <Box
                                    component="img"
                                    src={iconPreview}
                                    alt="Icon Preview"
                                    sx={{ width: 128, height: 128, borderRadius: '8px', border: '1px solid #ccc' }}
                                />
                                <Button variant="text" color="secondary" onClick={handleRemoveIcon} sx={{ mt: 1 }}>
                                    Remove Icon
                                </Button>
                            </Box>
                        )}
                    </Box>

                    <FormControl fullWidth margin="normal" required>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={formData.category_id}
                            onChange={handleChange}
                            name="category_id"
                            label="Category"
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {message && (
                        <Typography
                            variant="body1"
                            color={message.includes('successfully') ? "primary" : "error"}
                            sx={{ mt: 2 }}
                        >
                            {message}
                        </Typography>
                    )}
                </form>
            </Box>
            <FileSelectionPopup
                open={fileSelectionOpen}
                onSelect={handleFileSelect}
                onClose={() => {
                    setFileSelectionContext(null);
                    setFileSelectionOpen(false);
                }}
            />
        </Dialog>
    );
};

NotificationFormDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['create', 'edit']).isRequired,
    initialData: PropTypes.object, // Notification object for edit mode
    onSuccess: PropTypes.func // Callback after successful create/update
};

export default NotificationFormDialog;