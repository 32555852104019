// src/components/generic/AppTitleText.js

import React from 'react';
import { Typography } from '@mui/material';

const AppTitleText = () => {
    return (
        <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
                fontFamily: 'Roboto, sans-serif', // Use a modern font
                fontWeight: 'bold', // Make the text bold
                letterSpacing: '2px', // Add letter spacing
                background: 'linear-gradient(90deg, #ff7eb3, #ff4081, #ff80ab)', // Gradient with pink shades
                WebkitBackgroundClip: 'text', // Clip the gradient to the text
                WebkitTextFillColor: 'transparent', // Make the text transparent to show the gradient
                textTransform: 'uppercase', // Transform the text to uppercase for a modern look
            }}
        >
            SATIT CONNECT
        </Typography>

    );
}

export default AppTitleText;