import React, { useEffect, useState } from 'react';
import parentRelationService from '../services/parentRelationService';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    Snackbar,
    Alert,
    Chip,
    Grid,
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { QRCodeCanvas } from 'qrcode.react';
import Box from '@mui/material/Box';

function MyParentPage() {
    const [parents, setParents] = useState([]);
    const [error, setError] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pairingCode, setPairingCode] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [timer, setTimer] = useState(300); // 5 minutes in seconds
    const [timerActive, setTimerActive] = useState(false);

    // Timer effect for countdown
    useEffect(() => {
        let interval = null;
        if (timerActive && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            handleClose();
        }
        return () => clearInterval(interval);
    }, [timerActive, timer]);

    async function generatePairingCode() {
        try {
            const response = await parentRelationService.generatePairingCode();
            return response;
        } catch (error) {
            console.error(error);
            setError("Failed to generate pairing code.");
            throw error;
        }
    }

    useEffect(() => {
        parentRelationService.getParents()
            .then((data) => {
                if (Array.isArray(data)) {
                    setParents(data);
                } else if (data === "") {
                    setParents([]); // Treat empty string as no parents
                } else {
                    console.error("Invalid data format:", data);
                    setError("Failed to load parent list.");
                }
            })
            .catch((error) => {
                console.error("Error fetching parents:", error);
                setError("An error occurred while fetching parents.");
            });
    }, []);

    const handleFabClick = async () => {
        try {
            const code = await generatePairingCode();
            setPairingCode(code);
            setDialogOpen(true);
            setTimer(300); // Reset timer to 5 minutes
            setTimerActive(true);
        } catch (err) {
            // Error already handled in generatePairingCode
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
        setTimerActive(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(pairingCode)
            .then(() => {
                setCopySuccess(true);
            })
            .catch((err) => {
                console.error("Failed to copy!", err);
            });
    };

    // Format timer as mm:ss
    const formatTimer = (seconds) => {
        const m = Math.floor(seconds / 60).toString().padStart(2, '0');
        const s = (seconds % 60).toString().padStart(2, '0');
        return `${m}:${s}`;
    };

    // Helper function to get initials from name
    const getInitials = (givenName, familyName) => {
        const firstInitial = givenName ? givenName.charAt(0).toUpperCase() : '';
        const lastInitial = familyName ? familyName.charAt(0).toUpperCase() : '';
        return `${firstInitial}${lastInitial}`;
    };

    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>
                My Parents
            </Typography>

            {/* Error Handling */}
            {error ? (
                <Typography color="error" variant="body1">
                    {error}
                </Typography>
            ) : (
                <List>
                    {parents.length > 0 ? (
                        parents.map((parent) => (
                            <ListItem key={parent.id} alignItems="flex-start" divider>
                                <ListItemAvatar>
                                    <Avatar>
                                        {getInitials(parent.given_name, parent.family_name)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="h6">
                                            {parent.given_name} {parent.family_name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container alignItems="center" spacing={0.5}>
                                                    <Grid item>
                                                        <EmailIcon fontSize="small" color="action" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2" color="textPrimary">
                                                            {parent.upn}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {parent.email_verified ? (
                                                            <Tooltip title="Email Verified">
                                                                <CheckCircleIcon fontSize="small" color="success" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Email Not Verified">
                                                                <ErrorIcon fontSize="small" color="error" />
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container alignItems="center" spacing={0.5}>
                                                    <Grid item>
                                                        <PhoneIcon fontSize="small" color="action" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2" color="textPrimary">
                                                            {parent.phone_number || 'N/A'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        ))
                    ) : (
                        <Typography variant="body1">You have no parent linked.</Typography>
                    )}
                </List>
            )}

            {/* Floating Action Button - Bottom Right */}
            <Fab
                color="primary"
                aria-label="Generate Pairing Code"
                onClick={handleFabClick}
                style={{
                    position: 'fixed',
                    bottom: '75px',
                    right: '15px',
                }}
                variant='extended'
            >
                <VpnKeyIcon style={{ marginRight: 8 }} />
                Pairing Code
            </Fab>

            {/* Pairing Code Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="pairing-code-dialog-title"
            >
                <DialogTitle id="pairing-code-dialog-title">
                    Your Pairing Code
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: '100%', maxWidth: 300, margin: '0 auto' }}
                    >
                        <QRCodeCanvas value={pairingCode} style={{ width: '100%', height: 'auto' }} />
                        <Box
                        pt={1}
                            sx={{
                                width: '90%',
                                maxHeight: '200px',
                                overflowY: 'auto',
                                padding: 2,
                                border: '1px solid #ccc',
                                borderRadius: 1,
                                marginBottom: 2,
                            }}
                        >
                            <Typography variant="h5" align="center" gutterBottom>
                                {pairingCode}
                            </Typography>
                        </Box>
                        <Tooltip title="Copy to clipboard">
                            <Button
                                variant="contained"
                                startIcon={<ContentCopyIcon />}
                                onClick={handleCopy}
                                fullWidth
                                sx={{ marginTop: 2 }}
                            >
                                Copy Code
                            </Button>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary" align="center" sx={{ marginTop: 2 }}>
                            Please enter this code in the parent application. The code will expire in {formatTimer(timer)} minutes and won't be shown again if you close this popup.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Copy Success Snackbar */}
            <Snackbar
                open={copySuccess}
                autoHideDuration={3000}
                onClose={() => setCopySuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setCopySuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Pairing code copied to clipboard!
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default MyParentPage;
