import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LinkIcon from '@mui/icons-material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ContactsIcon from '@mui/icons-material/Contacts';
import useUserStore from '../../stores/userStore';

const BOTTOM_BAR_HEIGHT = 56; // Define the height in pixels
const SELECTED_COLOR = '#d81b60'; // Define a darker pink color variable

// Mapping of userType to relation details
const USER_TYPE_MAPPING = {
  1: {
    shown: true,
    name: 'My Parents',
    icon: <FamilyRestroomIcon />,
  },
  2: {
    shown: true,
    name: 'My Children',
    icon: <ChildCareIcon />,
  },
  // Add more mappings as needed
};

function BottomBar() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const userInfo = useUserStore((state) => state.userInfo);

  // Determine the current tab based on the URL path
  const getCurrentTab = () => {
    switch (location.pathname) {
      case '/dashboard':
        return 'dashboard';
      case '/notifications':
        return 'notifications';
      case '/relation/my-parents':
      case '/relation/my-children':
        return 'parent-relation';
      case '/misc':
      case '/notificationsadmin/create':
      case '/notificationsadmin/send':
      case '/notificationsadmin/management':
      case '/notificationsadmin':
        return 'misc';
      case '/directory':
        return 'directory';
      default:
        return 'home';
    }
  };

  const [value, setValue] = useState(getCurrentTab());
  const [relationShown, setRelationShown] = useState(false);
  const [relationName, setRelationName] = useState('');
  const [relationIcon, setRelationIcon] = useState(null); // Initialize as null
  const [directoryShown, setDirectoryShown] = useState(false);

  // Synchronize `value` with the location path on initial render and navigation changes
  useEffect(() => {
    setValue(getCurrentTab());
  }, [location.pathname]);

  // Update relation based on userInfo changes
  useEffect(() => {
    if (!userInfo) {
      setRelationShown(false);
      setDirectoryShown(false);
      return;
    }
    
    if (userInfo.is_admin) {
      setDirectoryShown(true);
    }

    if (userInfo.userType) {
      const userTypeInfo = USER_TYPE_MAPPING[userInfo.userType];
      if (userTypeInfo && userTypeInfo.shown) {
        setRelationShown(true);
        setRelationName(userTypeInfo.name);
        setRelationIcon(userTypeInfo.icon);
      } else {
        setRelationShown(false);
      }
    } else {
      setRelationShown(false);
    }

  }, [userInfo]);

  // Handle tab changes
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setValue(newValue);
      switch (newValue) {
        case 'dashboard':
          navigate('/dashboard');
          break;
        case 'notifications':
          navigate('/notifications');
          break;
        case 'parent-relation':
          if (relationName === 'My Parents') {
            navigate('/relation/my-parents');
          } else if (relationName === 'My Children') {
            navigate('/relation/my-children');
          }
          break;
        case 'misc':
          navigate('/misc');
          break;
        case 'directory':
          navigate('/directory');
          break;
        default:
          navigate('/');
      }
    }
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: `${BOTTOM_BAR_HEIGHT}px`,
        zIndex: 1200, // Ensure it's above all other content
      }}
      elevation={6}
    >
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Dashboard"
          showLabel={true}
          value="dashboard"
          icon={<DashboardIcon />}
          aria-label="Navigate to Dashboard"
          sx={{
            '&.Mui-selected': {
              color: SELECTED_COLOR,
            },
          }}
        />
        <BottomNavigationAction
          label="Notifications"
          showLabel={true}
          value="notifications"
          icon={<NotificationsIcon />}
          aria-label="Navigate to Notifications"
          sx={{
            '&.Mui-selected': {
              color: SELECTED_COLOR,
            },
          }}
        />
        { directoryShown && (<BottomNavigationAction
          label="Directory"
          showLabel={true}
          value="directory"
          icon={<ContactsIcon />}
          aria-label="Navigate to Directory"
          sx={{
            '&.Mui-selected': {
              color: SELECTED_COLOR,
            },
          }}
        />)}
        {relationShown && (
          <BottomNavigationAction
            label={relationName}
            showLabel={true}
            value="parent-relation"
            icon={relationIcon || <LinkIcon />} // Fallback to LinkIcon if relationIcon is not set
            aria-label={`Navigate to ${relationName}`}
            sx={{
              '&.Mui-selected': {
                color: SELECTED_COLOR,
              },
            }}
          />
        )}
        <BottomNavigationAction
          label="More"
          showLabel={true}
          value="misc"
          icon={<MoreHorizIcon />}
          aria-label="Navigate to More"
          sx={{
            '&.Mui-selected': {
              color: SELECTED_COLOR,
            },
          }}
        />
      </BottomNavigation>
    </Paper>
  );
}

export { BOTTOM_BAR_HEIGHT }; // Export the height for reuse
export default BottomBar;
