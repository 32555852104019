// src/pages/LoginPage.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid2, Paper, Typography, Box, Button } from '@mui/material';
import LoginButtonSSO from '../components/loginPage/LoginButtonSSO';
import authenticationService from '../services/authenticationService';
import LoginForm from '../components/loginPage/LoginForm';
import RegisterForm from '../components/loginPage/RegisterForm'; // Import RegisterForm
import AppTitleText from '../components/generic/AppTitleText';

const LoginPage = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showParentLogin, setShowParentLogin] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false); // New state for registration

  // Check if the user is already logged in
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const loggedIn = await authenticationService.isLoggedIn();
        setIsLoggedIn(loggedIn);
        if (loggedIn) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };

    checkLoginStatus();
  }, [navigate]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f0f2f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '40px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <AppTitleText />
        <Grid2
          container
          spacing={4}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* SSO Login Section */}
          {!showParentLogin && !isRegistering && (
            <Grid2 item sx={{ width: '100%' }}>
              <LoginButtonSSO />
            </Grid2>
          )}

          {/* "Login as Parent" Button */}
          {!showParentLogin && !isRegistering && (
            <Grid2 item sx={{ width: '100%' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowParentLogin(true)}
                fullWidth
                sx={{
                  padding: '12px 24px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  textTransform: 'none',
                }}
              >
                Login as Parent
              </Button>
            </Grid2>
          )}

          {/* Parent Login Form */}
          {showParentLogin && !isRegistering && (
            <Grid2 item sx={{ width: '100%' }}>
              <LoginForm
                onCancel={() => setShowParentLogin(false)}
                onRegister={() => setIsRegistering(true)} // Handle switching to Register
              />
            </Grid2>
          )}

          {/* Registration Form */}
          {isRegistering && (
            <Grid2 item sx={{ width: '100%' }}>
              <RegisterForm
                onCancel={() => setIsRegistering(false)}
              />
            </Grid2>
          )}
        </Grid2>
      </Paper>
    </Box>
  );
};

export default LoginPage;
