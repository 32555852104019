import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Box,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import FileTreeView from './FileTreeView';
import { listFiles } from '../../services/storageService';

export default function FileSelectionPopup({ open, onClose, onSelect }) {
  const [treeItems, setTreeItems] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (open) {
      fetchFiles();
    }
  }, [open]);

  const fetchFiles = async () => {
    try {
      const data = await listFiles();
      if (data) {
        const items = buildTreeItems(data);
        setTreeItems(items);
      } else {
        console.error('No data received from listFiles.');
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const buildTreeItems = (treeData) => {
    const traverse = (nodes) =>
      Object.values(nodes).map((node) => ({
        id: node.path,
        label: node.name,
        icon: node.isFile ? null : null,
        children: Object.keys(node.children).length > 0 ? traverse(node.children) : [],
        isFile: node.isFile,
      }));
    return traverse(treeData);
  };

  // Helper function to find an item by ID
  const findItemById = (id, items) => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItemById(id, item.children);
        if (found) return found;
      }
    }
    return null;
  };

  const handleSelect = () => {
    if (selectedFiles.length !== 1) {
      alert('Please select a single file.');
      return;
    }

    onSelect(selectedFiles[0]);
    onClose();
  };

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
      <DialogTitle>
        Select File
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FileTreeView
          treeItems={treeItems}
          selectedFiles={selectedFiles}
          onSelectedItemsChange={(event, itemIds) => {
            const updatedSelection = itemIds.map((id) => {
              const item = findItemById(id, treeItems);
              return { path: item.id, isFile: item.isFile };
            });
            setSelectedFiles(updatedSelection);
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={handleSelect} variant="contained">
            Select
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
