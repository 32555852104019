// services/nativeService.js

const CACHE_NAME = 'audio-cache';

const NOTIFICATION_SOUND_URL = '/audio/notification.mp3';

const AUDIO_URLS = [
    NOTIFICATION_SOUND_URL,
];

const cacheAudioFiles = async () => {
    const cache = await caches.open(CACHE_NAME);
    for (const url of AUDIO_URLS) {
        const response = await fetch(url);
        await cache.put(url, response);
    }
};

const cacheAudioFile = async (url) => {
    const cache = await caches.open(CACHE_NAME);
    const cachedResponse = await cache.match(url);
    if (!cachedResponse) {
        const response = await fetch(url);
        await cache.put(url, response);
    }
};

const playCachedAudio = async (url) => {
    const cache = await caches.open(CACHE_NAME);
    const cachedResponse = await cache.match(url);
    if (cachedResponse) {
        const audioBlob = await cachedResponse.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
    } else {
        console.error('Audio file not found in cache. Caching and playing now.');
        await cacheAudioFile(url);
        await playCachedAudio(url);
    }
};

const shakeScreen = () => {
    const body = document.body;
    body.classList.add('shake');
    setTimeout(() => {
        body.classList.remove('shake');
    }, 500); // Shake duration should match the CSS animation duration
};

const notificationSound = async () => {
    await playCachedAudio(NOTIFICATION_SOUND_URL);
};

const vibrate = () => {
    if (navigator.vibrate) {
        navigator.vibrate(200); // Vibrate for 200 milliseconds
    }
};

const nativeService = {
    notificationSound,
    vibrate,
    cacheAudioFiles,
    cacheAudioFile,
    playCachedAudio,
    shakeScreen,
};

export default nativeService;