// src/dashboardPage/ApplicationGrid.js

import React from 'react';
import { Grid, Typography, IconButton, Box, Paper } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
// Add imports for specific icons
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import ScienceIcon from '@mui/icons-material/Science';
import BookIcon from '@mui/icons-material/Book';
import LockIcon from '@mui/icons-material/Lock';

const Applications = [
    {
        name: 'CUDPLUS+',
        tooltip: 'eLearning Platform',
        url: 'https://cudplus.onsmart.school/',
        icon: <SchoolIcon />,
    },
    {
        name: 'CUDDB',
        tooltip: 'Employee Services',
        url: 'https://cuddb.com/',
        icon: <PeopleIcon />,
    },
    {
        name: 'CUDReg',
        tooltip: 'Course Registration',
        url: 'https://cudreg.com/',
        icon: <EventIcon />,
    },
    {
        name: 'STEMLAB',
        tooltip: 'STEM Lab',
        url: 'https://stemlab.center',
        icon: <ScienceIcon />,
    },
    {
        name: 'Moodle',
        tooltip: 'eLearning Platform',
        url: 'https://elearn.satitm.chula.ac.th',
        icon: <BookIcon />,
    },
    {
        name: 'SSO',
        tooltip: 'Single Sign-On Portal',
        url: 'https://sso.satitm.chula.ac.th',
        icon: <LockIcon />,
    },
];

const ApplicationGrid = () => {
    return (
        <Box p={2}>
            <Paper
                elevation={3}
                sx={{
                    padding: 2,
                    textAlign: 'center',
                }}
            >
            
            <Typography variant="h5" gutterBottom>
                Applications
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {Applications.map((app, index) => (
                    <Grid item xs={4} sm={3} md={2} key={index} style={{ textAlign: 'center' }}>
                        <IconButton href={app.url} target="_blank" color="primary" size="large">
                            {app.icon}
                        </IconButton>
                        <Typography variant="subtitle1">{app.name}</Typography>
                    </Grid>
                ))}
            </Grid>
            </Paper>
        </Box>
    );
}

export default ApplicationGrid;