import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import authenticationService from '../services/authenticationService';

const useUserStore = create(
  persist(
    (set) => ({
      userInfo: null,
      setUserInfo: (info) => set({ userInfo: info }),
      clearUserInfo: () => set({ userInfo: null }),
      initializeUserInfo: async () => {
        try {
          if (!useUserStore.getState().userInfo && authenticationService.isLoggedIn()) {
            await useUserStore.getState().fetchUserInfo();
          }
        } catch (error) {
          console.error('Failed to initialize user info:', error);
        }
      },
      fetchUserInfo: async () => {
        try {
          const info = await authenticationService.getUserInfoNoCache();
          set({ userInfo: info });
        } catch (error) {
          console.error('Failed to fetch user info:', error);
        }
      },
    }),
    {
      name: 'user-store', // unique name
      getStorage: () => localStorage, // use localStorage
    }
  )
);

useUserStore.getState().initializeUserInfo();

export default useUserStore;