
// src/components/notificationManagementPage/PaginationControls.js
import React from 'react';
import { Box, Pagination } from '@mui/material';

const PaginationControls = ({ totalPages, currentPage, setCurrentPage }) => {
  if (totalPages <= 1) return null;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        color="primary"
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

export default PaginationControls;