import React from 'react';
import { Box } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

export default function FileTreeView({
  treeItems,
  selectedFiles,
  onSelectedItemsChange,
}) {
  const renderLabel = (node) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 1,
        borderRadius: 1,
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {node.icon}
        <Box sx={{ ml: 1 }}>{node.label}</Box>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 100px)',
      }}
    >
      <RichTreeView
        items={treeItems}
        selectedItems={selectedFiles.map((item) => item.path)}
        multiSelect={true}
        onSelectedItemsChange={onSelectedItemsChange}
        renderLabel={renderLabel}
      />
    </Box>
  );
}
