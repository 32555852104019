
// src/components/directory/UserDetailsDialog.js
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../config';
import PropTypes from 'prop-types';

const UserDetailsDialog = ({ open, onClose, upn, userType }) => {
  const [userData, setUserData] = useState(null);
  const [relatedData, setRelatedData] = useState([]); // For students: parents; For parents: students
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingRelated, setLoadingRelated] = useState(false);
  const [errorUser, setErrorUser] = useState(null);
  const [errorRelated, setErrorRelated] = useState(null);

  // Fetch main user data
  useEffect(() => {
    if (!open || !upn) return; // No need to fetch if dialog is closed or no UPN

    const fetchUserData = async () => {
      setLoadingUser(true);
      setErrorUser(null);
      setUserData(null);
      setRelatedData([]);
      setErrorRelated(null);

      try {
        // Fetch main user info
        const response = await axios.get(`${API_URL}/api/directory/users/${encodeURIComponent(upn)}`);
        const mainData = response.data;
        setUserData(mainData);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setErrorUser('Failed to load user details.');
      } finally {
        setLoadingUser(false);
      }
    };

    fetchUserData();
  }, [open, upn]);

  // Fetch related data after main user data is fetched
  useEffect(() => {
    if (!userData || !userType) return;

    const fetchRelatedData = async () => {
      setLoadingRelated(true);
      setErrorRelated(null);
      setRelatedData([]);

      try {
        let response;
        if (userType === 'student') {
          response = await axios.get(`${API_URL}/api/psrelation/get_parents`, { params: { upn }});
        } else if (userType === 'parent') {
          response = await axios.get(`${API_URL}/api/psrelation/get_students`, { params: { upn }});
        } else {
          // Employees don't have related data in the current context
          setRelatedData([]);
          setLoadingRelated(false);
          return;
        }
        setRelatedData(response.data || []);
      } catch (err) {
        console.error('Error fetching related data:', err);
        setErrorRelated('Failed to load related data.');
      } finally {
        setLoadingRelated(false);
      }
    };

    fetchRelatedData();
  }, [userData, userType, upn]);

  const handleClose = () => {
    onClose();
  };

  // Helper function to safely display fields
  const safeField = (field) => (field ? field : 'N/A');

  // Render functions for different user types
  const renderEmployeeInfo = () => (
    <>
      <Typography variant="subtitle1"><strong>Display Name:</strong> {safeField(userData.displayName)}</Typography>
      <Typography variant="subtitle1"><strong>Title:</strong> {safeField(userData.title)}</Typography>
      <Typography variant="subtitle1"><strong>Department:</strong> {safeField(userData.department)}</Typography>
      <Typography variant="subtitle1"><strong>Email:</strong> {safeField(userData.mail)}</Typography>
    </>
  );

  const renderParentInfo = () => (
    <>
      <Typography variant="subtitle1"><strong>Given Name:</strong> {safeField(userData.given_name)}</Typography>
      <Typography variant="subtitle1"><strong>Family Name:</strong> {safeField(userData.family_name)}</Typography>
      <Typography variant="subtitle1"><strong>Phone Number:</strong> {safeField(userData.phone_number)}</Typography>
      <Typography variant="subtitle1"><strong>Email Verified:</strong> {userData.email_verified ? 'Yes' : 'No'}</Typography>
      
      {/* Related students */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Linked Students:</Typography>
        {loadingRelated && <CircularProgress size={20} sx={{ mt: 1 }} />}
        {errorRelated && <Typography color="error">{errorRelated}</Typography>}
        {!loadingRelated && !errorRelated && relatedData.length === 0 && (
          <Typography variant="body2">No linked students found.</Typography>
        )}
        {!loadingRelated && !errorRelated && relatedData.length > 0 && (
          relatedData.map((student) => (
            <Typography key={student.userPrincipalName} variant="body2">
              {student.givenName} {student.sn} ({student.userPrincipalName})
            </Typography>
          ))
        )}
      </Box>
    </>
  );

  const renderStudentInfo = () => (
    <>
      <Typography variant="subtitle1"><strong>Given Name:</strong> {safeField(userData.givenName)}</Typography>
      <Typography variant="subtitle1"><strong>Surname:</strong> {safeField(userData.sn)}</Typography>
      <Typography variant="subtitle1"><strong>Email:</strong> {safeField(userData.mail || userData.userPrincipalName)}</Typography>
      
      {/* Related parents */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Linked Parents:</Typography>
        {loadingRelated && <CircularProgress size={20} sx={{ mt: 1 }} />}
        {errorRelated && <Typography color="error">{errorRelated}</Typography>}
        {!loadingRelated && !errorRelated && relatedData.length === 0 && (
          <Typography variant="body2">No linked parents found.</Typography>
        )}
        {!loadingRelated && !errorRelated && relatedData.length > 0 && (
          relatedData.map((parentObj) => (
            <Typography key={parentObj.upn} variant="body2">
              {parentObj.given_name} {parentObj.family_name} ({parentObj.upn})
            </Typography>
          ))
        )}
      </Box>
    </>
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>User Details</DialogTitle>
      <DialogContent dividers>
        {loadingUser && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="150px">
            <CircularProgress />
          </Box>
        )}
        {errorUser && (
          <Typography color="error">{errorUser}</Typography>
        )}
        {!loadingUser && !errorUser && userData && (
          <Box>
            <Typography variant="h6" gutterBottom>Primary Info</Typography>
            {/* Common fields */}
            <Typography variant="subtitle1"><strong>UPN:</strong> {safeField(userData.userPrincipalName || userData.upn)}</Typography>

            {userType === 'employee' && renderEmployeeInfo()}
            {userType === 'parent' && renderParentInfo()}
            {userType === 'student' && renderStudentInfo()}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

// PropTypes for type checking
UserDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  upn: PropTypes.string,
  userType: PropTypes.oneOf(['student', 'employee', 'parent']),
};

// Default props
UserDetailsDialog.defaultProps = {
  upn: '',
  userType: 'employee',
};

export default UserDetailsDialog;