// services/notificationService.js

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyCEVA7z9SWbjdzeMYHlamqN77dkr6NPu_8",
    authDomain: "satitm-app.firebaseapp.com",
    projectId: "satitm-app",
    storageBucket: "satitm-app.firebasestorage.app",
    messagingSenderId: "990569970383",
    appId: "1:990569970383:web:327bb9d3ded0c8e25095ba",
    measurementId: "G-17SGHFH9SK"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let messaging;
try {
    messaging = getMessaging(app);
} catch (error) {
    console.error('This browser doesn\'t support the API\'s required to use the Firebase SDK.', error);
}

async function getFCMToken() {
    if (!messaging) {
        console.log('Messaging is not supported in this browser.');
        return null;
    }

    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BM8S8td7gV12KoTRnVzPgFEXqsBkWu6EtX1ANzaBMob3DuTmcevAN2tq0MlhLHtvf2N4p4-G78z3u8d-2IDDk7g' });
        if (currentToken) {
            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            return null;
        }
    } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
        return null;
    }
}

async function unsubscribe() {
    if (!messaging) {
        console.log('Messaging is not supported in this browser.');
        return;
    }
    try {
        const currentToken = await getFCMToken();
        if (!currentToken) {
            console.log('No registration token available.');
            return;
        }
        await axios.post('/api/notify/unsubscribe', {
            token: currentToken
        });

    } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
        return;
    }

}


async function registerPushNotification() {
    const currentToken = await getFCMToken();
    if (currentToken) {
        console.log('FCM Token:', currentToken);
    } else {
        console.error('Failed to get FCM token.');
        return false; // Return false if token retrieval fails
    }

    try {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            console.error('Notification permission denied.');
            return false; // Return false if permission is denied
        }

        await axios.post('/api/notify/subscribe', {
            token: currentToken
        });
        return true; // Return true if registration is successful
    } catch (error) {
        if (error.response && error.response.status === 409) {
            console.warn('Token already registered.');
            return true; // Return true if the token is already registered
        } else {
            console.error('An error occurred while registering the token. ', error);
            return false; // Return false if an error occurs
        }
    }
}



function getNotificationPermissionStatus() {
    return Notification.permission;
}

function isNotificationPermissionGranted() {
    return (getNotificationPermissionStatus()) === 'granted';
}

async function createNotificationMessage({ title, short_message, html_message, icon, category_id }) {
    try {
        const response = await axios.post(`/api/notify/createMessage`, {
            title,
            short_message,
            html_message,
            icon,
            category_id,
        });
        return response.data;
    } catch (error) {
        console.error('Error creating notification message:', error.response?.data || error.message);
        throw error;
    }
}

async function getNotificationMessageById(messageId) {
    try {
        const response = await axios.get(`/api/notify/message/${messageId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching notification message:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToAllUsers(messageId) {
    try {
        const response = await axios.post(`/api/notify/send/all`, { messageId });
        return response.data;
    } catch (error) {
        console.error('Error sending notification to all users:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToGroup(messageId, group_id, send_target = 'self') {
    try {
        const response = await axios.post(`/api/notify/send/group`, {
            messageId,
            group_id,
            send_target,
        });
        return response.data;
    } catch (error) {
        console.error('Error sending notification to group:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToAllEmployees(messageId) {
    try {
        const response = await axios.post(`/api/notify/send/employees`, { messageId });
        return response.data;
    } catch (error) {
        console.error('Error sending notification to all employees:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToAllParents(messageId) {
    try {
        const response = await axios.post(`/api/notify/send/parents`, { messageId });
        return response.data;
    } catch (error) {
        console.error('Error sending notification to all parents:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToAllStudents(messageId) {
    try {
        const response = await axios.post(`/api/notify/send/students`, { messageId });
        return response.data;
    } catch (error) {
        console.error('Error sending notification to all students:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToUsers(messageId, user_upns, send_target = 'self') {
    try {
        const response = await axios.post(`/api/notify/send/users`, {
            messageId,
            user_upns,
            send_target,
        });
        return response.data;
    } catch (error) {
        console.error('Error sending notification to users:', error.response?.data || error.message);
        throw error;
    }
}

async function getNotificationsForUser(page = 1, pageSize = 10, categories = [], searchQuery = undefined) {
    try {
        const params = {
            page,
            pageSize
        };

        if (categories.length > 0) {
            params.categories = categories.join(',');
        }

        if (searchQuery !== undefined) {
            params.searchQuery = searchQuery;
        }

        const response = await axios.get(`/api/notify/notifications`, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching notifications for user:', error.response?.data || error.message);
        throw error;
    }
}

async function getNotificationCategories() {
    try {
        const response = await axios.get(`/api/notify/categories`);
        return response.data;
    } catch (error) {
        console.error('Error fetching notification categories:', error.response?.data || error.message);
        throw error;
    }
}

async function createNotificationCategory(name, description) {
    try {
        const response = await axios.post(`/api/notify/categories`, { name, description });
        return response.data;
    } catch (error) {
        console.error('Error creating notification category:', error.response?.data || error.message);
        throw error;
    }
}

async function updateNotificationCategory(id, name, description) {
    try {
        const response = await axios.put(`/api/notify/categories/${id}`, { name, description });
        return response.data;
    } catch (error) {
        console.error('Error editing notification category:', error.response?.data || error.message);
        throw error;
    }
}

async function deleteNotificationCategory(id) {
    try {
        const response = await axios.delete(`/api/notify/categories/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting notification category:', error.response?.data || error.message);
        throw error;
    }
}

async function updateNotificationMessage(id, title, short_message, html_message, icon, category_id) {
    try {
        const response = await axios.put(`/api/notify/message/${id}`, {
            title,
            short_message,
            html_message,
            icon,
            category_id
        });
        return response.data;
    } catch (error) {
        console.error('Error updating notification message:', error.response?.data || error.message);
        throw error;
    }
}

async function deleteNotificationMessage(id) {
    try {
        const response = await axios.delete(`/api/notify/message/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting notification message:', error.response?.data || error.message);
        throw error;
    }
}

async function getNotificationMessageList(page = 1, pageSize = 10, categories = [], searchQuery = undefined) {
    try {
        const params = { page, pageSize, categories };
        if (searchQuery !== undefined) {
            params.searchQuery = searchQuery;
        }
        const response = await axios.get(`/api/notify/messages`, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching notification messages:', error.response?.data || error.message);
        throw error;
    }
}

async function getNotificationGroupList() {
    try {
        const response = await axios.get(`/api/notify/groups`);
        return response.data;
    } catch (error) {
        console.error('Error fetching notification groups:', error.response?.data || error.message);
        throw error;
    }
}

async function sendNotificationToTargets(messageId, targets) {
    try {
        const response = await axios.post(`/api/notify/send/bulk`, {
            messageId,
            targets,
        });
        return response.data;
    }
    catch (error) {
        console.error('Error sending notification to targets:', error.response?.data || error.message);
        throw error;
    }
}

async function updateNotification(messageId, data) {
    try {
        const response = await axios.put(`/api/notify/message/${messageId}`, data);
        return response.data;
    }
    catch (error) {
        console.error('Error updating notification:', error.response?.data || error.message);
        throw error;
    }
}

async function deleteNotification(messageId) {
    try {
        const response = await axios.delete(`/api/notify/message/${messageId}`);
        return response.data;
    }
    catch (error) {
        console.error('Error deleting notification:', error.response?.data || error.message);
        throw error;
    }
}



export {
    createNotificationMessage,
    getNotificationMessageById,
    sendNotificationToAllUsers,
    sendNotificationToGroup,
    sendNotificationToAllEmployees,
    sendNotificationToAllParents,
    sendNotificationToAllStudents,
    sendNotificationToUsers,
    getNotificationsForUser,
    messaging,
    onMessage,
    getNotificationCategories,
    createNotificationCategory,
    updateNotificationCategory,
    deleteNotificationCategory,
    updateNotificationMessage,
    deleteNotificationMessage,
    getNotificationMessageList,
    getNotificationGroupList,
    sendNotificationToTargets,
    updateNotification,
    deleteNotification,
    analytics,
    unsubscribe,
};

const notificationService = {
    getFCMToken,
    registerPushNotification,
    getNotificationPermissionStatus,
    isNotificationPermissionGranted,
    createNotificationMessage,
    getNotificationMessageById,
    sendNotificationToAllUsers,
    sendNotificationToGroup,
    sendNotificationToAllEmployees,
    sendNotificationToAllParents,
    sendNotificationToAllStudents,
    sendNotificationToUsers,
    getNotificationsForUser,
    messaging,
    onMessage,
    getNotificationCategories,
    createNotificationCategory,
    updateNotificationCategory,
    deleteNotificationCategory,
    updateNotificationMessage,
    deleteNotificationMessage,
    getNotificationMessageList,
    getNotificationGroupList,
    sendNotificationToTargets,
    updateNotification,
    deleteNotification,
    analytics,
    unsubscribe,
};

export default notificationService;