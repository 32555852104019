// src/services/storageService.js

import axios from 'axios';

async function uploadFile(file, filename, path) {
    // Remove leading slash from path
    if (path && path.startsWith('/')) {
        path = path.slice(1);
    }
    // Remove trailing slash from path
    if (path && path.endsWith('/')) {
        path = path.slice(0, -1);
    }
    const formData = new FormData();
    formData.append('file', file);

    const url = path ? `/api/storage/file/${path}/${filename}` : `/api/storage/file/${filename}`;

    const response = await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
}

async function deleteFile(filename, path) {
    const url = path ? `/api/storage/file/${path}/${filename}` : `/api/storage/file/${filename}`;
    const response = await axios.delete(url);
    return response.data;
}

async function listFiles() { // Removed 'path' parameter
    const response = await axios.get(`/api/storage/files`);
    const filePaths = response.data;

    if (!Array.isArray(filePaths)) {
        throw new Error('Invalid data format received from the server.');
    }

    // Convert file paths to tree structure
    function buildTree(paths) {
        const tree = {};
        paths.forEach(filePath => {
            const parts = filePath.split('/').filter(Boolean);
            let current = tree;
            parts.forEach((part, index) => {
                if (!current[part]) {
                    current[part] = {
                        name: part,
                        children: {},
                        path: parts.slice(0, index + 1).join('/'),
                        isFile: index === parts.length - 1
                    };
                }
                current = current[part].children;
            });
        });
        return tree;
    }

    const treeData = buildTree(filePaths);
    return treeData;
}

async function downloadFile(filename, path) {
    const url = path ? `/api/storage/file/${path}/${filename}` : `/api/storage/file/${filename}`;
    const response = await axios.get(url, {
        responseType: 'blob',
    });

    return response.data;
}

export { uploadFile, deleteFile, listFiles, downloadFile };