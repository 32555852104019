// src/pages/aboutPage.js

import React from 'react';
import { Typography, Container, Box, Card, CardContent, Avatar } from '@mui/material';

const AboutPage = () => {
    return (
        <Container>
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Avatar 
                    alt="SATIT CONNECT Logo" 
                    src="/public/logo.png" 
                    sx={{ width: 100, height: 100, margin: '0 auto', mb: 2 }}
                />
                <Typography variant="h4" gutterBottom>
                    SATIT CONNECT
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    App by Siwat System, 2024
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Built for Chulalongkorn University Demonstration Secondary School
                </Typography>
                <Card sx={{ mt: 4 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Backend API
                        </Typography>
                        <Typography variant="body2">
                            ad.satitm.chula.ac.th
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Contact
                        </Typography>
                        <Typography variant="body2">
                            Developer: Siwat Sirichai
                        </Typography>
                        <Typography variant="body2">
                            Email: siwat@siwatinc.com
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
}

export default AboutPage;