// hooks/useFCMListener.js

import { useEffect } from 'react';
import { messaging, onMessage } from '../services/notificationService';

const useFCMListener = (onMessageReceived) => {
    useEffect(() => {
        if (messaging) {
            const unsubscribe = onMessage(messaging, (payload) => {
                console.log('Message received. ', payload);
                if (onMessageReceived) {
                    onMessageReceived(payload);
                }
            });

            return () => {
                unsubscribe();
            };
        }
    }, [onMessageReceived]);
};

export default useFCMListener;