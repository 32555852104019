// services/directoryService.js

import { API_URL } from "../config";
import axios from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

// Constants for user types
const USER_TYPE = {
    UNKNOWN: 0,
    STUDENT: 1,
    PARENT: 2,
    STAFF: 3,
    TEACHER: 4
  };

// Function to fetch all users
async function fetchAllUsers() {
    try {
        const response = await axios.get(`${API_URL}/api/directory/users`);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch users:", error);
        throw error;
    }
}

// Define the Zustand store
const useDirectoryStore = create(
    devtools((set, get) => ({
        students: [],
        employees: [],
        parents: [],
        loading: false,
        error: null,

        fetchUsers: async () => {
            console.log("Fetching users...");
            set({ loading: true, error: null });
            try {
                const data = await fetchAllUsers();
                set({
                    students: data.students,
                    employees: data.employees,
                    parents: data.parents,
                    loading: false
                });
                // Print out the store
                console.log(get());
            } catch (error) {
                set({ error, loading: false });
            }
        },

        refreshUsers: async () => {
            await get().fetchUsers();
        },
    }))
);

// Refresh interval in milliseconds (e.g., 5 minutes)
const REFRESH_INTERVAL = 5 * 60 * 1000;

// Initialize by fetching once and setting up interval
useDirectoryStore.getState().fetchUsers();

const intervalId = setInterval(() => {
    useDirectoryStore.getState().fetchUsers();
}, REFRESH_INTERVAL);

// Optional: Provide a way to clear the interval if needed
const stopPeriodicRefresh = () => {
    clearInterval(intervalId);
};

export {
    useDirectoryStore,
    fetchAllUsers,
    stopPeriodicRefresh,
    USER_TYPE,
};

const directoryService = {
    useDirectoryStore,
    fetchAllUsers,
    stopPeriodicRefresh,
    USER_TYPE,
};

export default directoryService;