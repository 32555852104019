
import React from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const SortControls = ({ sortCriterion, onSortChange, onRefresh }) => (
  <Box 
    sx={{
      p: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    <Button startIcon={<RefreshIcon />} onClick={onRefresh} variant="text" color="secondary">
      Refresh
    </Button>
    <FormControl size="small" sx={{ minWidth: 180 }}>
      <InputLabel id="sort-label">Sort By</InputLabel>
      <Select
        labelId="sort-label"
        label="Sort By"
        value={sortCriterion}
        onChange={onSortChange}
      >
        <MenuItem value="givenName-asc">First Name (A-Z)</MenuItem>
        <MenuItem value="givenName-desc">First Name (Z-A)</MenuItem>
        <MenuItem value="sn-asc">Surname (A-Z)</MenuItem>
        <MenuItem value="sn-desc">Surname (Z-A)</MenuItem>
        <MenuItem value="userPrincipalName-asc">Email (A-Z)</MenuItem>
        <MenuItem value="userPrincipalName-desc">Email (Z-A)</MenuItem>
      </Select>
    </FormControl>
  </Box>
);

export default SortControls;