// src/components/loginPage/LoginForm.js

import React, { useState, useRef, useEffect } from 'react';
import { Button, TextField, Box, Typography, Fade, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import authenticationService from '../../services/authenticationService';

const LoginForm = ({ onCancel, onRegister }) => { // Receive onRegister as a prop
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null); // Changed to emailRef for consistency

  const handleLogin = async () => {
    // Reset error message
    setError('');

    // Validate input fields
    if (!username || !password) {
      setError('Please enter your username and password.');
      return;
    }

    setLoading(true);
    try {
      const response = await authenticationService.loginParent(username, password);
      if (response.success) {
        // Redirect to dashboard or another appropriate page
        window.location.href = '/dashboard'; // Alternatively, use navigate('/dashboard');
      } else {
        if (response.status === 401) {
          setError('Invalid username or password. Please try again.');
        } else {
          setError(response.message || 'Login failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred during login. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Focus on the username field when the form appears
  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  return (
    <Fade in={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          // padding: '20px',
          // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          // borderRadius: '10px',
          backgroundColor: 'transparent',
          width: '100%',
        }}
      >
        <Typography variant="h6" align="center">
          Parent Login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <TextField
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          inputRef={emailRef}
          fullWidth
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          fullWidth
          disabled={loading}
          sx={{
            padding: '12px 24px',
            fontSize: '16px',
            borderRadius: '8px',
            textTransform: 'none',
          }}
        >
          {loading ? 'Logging in...' : 'Login'}
        </Button>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">
            Don't have an account?{' '}
            <Link to="#" onClick={onRegister} style={{ textDecoration: 'none', color: '#1976d2' }}>
              Register here
            </Link>
          </Typography>
        </Box>
        <Button
          variant="text"
          color="secondary"
          onClick={onCancel}
          fullWidth
          sx={{
            textTransform: 'none',
          }}
        >
          Cancel
        </Button>
      </Box>
    </Fade>
  );
};

export default LoginForm;
