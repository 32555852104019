
// src/components/notificationManagementPage/NotificationsToolbar.js
import React from 'react';
import {
  Box,
  Toolbar,
  TextField,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Stack,
  Typography,
  CircularProgress,
  Tooltip,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const NotificationsToolbar = ({
  categories,
  selectedCategories,
  loadingCategories,
  errorCategories,
  searchTerm,
  setSearchTerm,
  handleSearch,
  setDebouncedSearchTerm,
  setCurrentPage,
  handleCategoryToggle,
  navigate,
  pageSize,
  setPageSize,
}) => {
  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Toolbar disableGutters sx={{ flexWrap: 'wrap', gap: 2 }}>
        {/* Categories Filter */}
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h6">Categories:</Typography>
          {loadingCategories ? (
            <CircularProgress size={24} />
          ) : errorCategories ? (
            <Typography color="error">{errorCategories}</Typography>
          ) : (
            <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
              {categories.map((cat) => (
                <Chip
                  key={cat.id}
                  label={cat.name}
                  color={selectedCategories.includes(cat.id) ? 'primary' : 'default'}
                  onClick={() => handleCategoryToggle(cat.id)}
                  variant={selectedCategories.includes(cat.id) ? 'filled' : 'outlined'}
                  aria-pressed={selectedCategories.includes(cat.id)}
                />
              ))}
              {categories.length === 0 && <Typography>No categories available</Typography>}
              {/* Plus Icon Button Added Here */}
              <Tooltip title="Add New Category">
                <IconButton
                  size="small"
                  onClick={() => navigate('/notificationsadmin/categories')}
                  aria-label="Add Category"
                  color="primary"
                  sx={{ ml: 1 }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Box>

        {/* Search Bar */}
        <Box display="flex" alignItems="center" gap={2} flexGrow={1}>
          <TextField
            variant="outlined"
            label="Search"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            sx={{ flexGrow: 1, minWidth: 200 }}
            inputProps={{
              'aria-label': 'search notifications',
            }}
          />
          <IconButton color="primary" onClick={handleSearch} aria-label="search">
            <SearchIcon />
          </IconButton>
          <Button
            variant="outlined"
            onClick={() => {
              setSearchTerm('');
              setDebouncedSearchTerm('');
              setCurrentPage(1);
            }}
          >
            Clear
          </Button>
        </Box>

        {/* Page Size Selector */}
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="page-size-label">Page Size</InputLabel>
          <Select
            labelId="page-size-label"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setCurrentPage(1); // Reset to first page
            }}
          >
            {[5, 10, 20, 50].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>
    </Paper>
  );
};

export default NotificationsToolbar;