// src/components/loginPage/LoginButtonSSO.js

import React from 'react';
import { Button, Box } from '@mui/material';
import authenticationService from '../../services/authenticationService';

const LoginButtonSSO = () => { // Removed unused onClick prop
  const handleLogin = () => {
    authenticationService.login();
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        fullWidth
        sx={{
          padding: '12px 24px',
          fontSize: '16px',
          borderRadius: '8px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          textTransform: 'none',
          width: '100%',
        }}
      >
        Login as Student / Staff
      </Button>
    </Box>
  );
};

export default LoginButtonSSO;
