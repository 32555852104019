
// src/components/NotificationsGrid.js
import React from 'react';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import NotificationCardItem from './NotificationCardItem';

const NotificationsGrid = ({
  loadingNotifications,
  errorNotifications,
  notificationList,
  handleEditClick,
  handleDelete,
  handleSend,
  handlePreview,
  fetchNotifications,
}) => {
  if (loadingNotifications) {
    return (
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      </Grid>
    );
  } else if (errorNotifications) {
    return (
      <Grid item xs={12}>
        <Box textAlign="center" mt={2}>
          <Typography color="error">{errorNotifications}</Typography>
          <Button variant="contained" onClick={fetchNotifications} sx={{ mt: 2 }}>
            Retry
          </Button>
        </Box>
      </Grid>
    );
  } else if (notificationList.length === 0) {
    return (
      <Grid item xs={12}>
        <Typography variant="body1">No notifications found.</Typography>
      </Grid>
    );
  } else {
    return notificationList.map((notification) => (
      <Grid item xs={12} sm={6} md={4} key={notification.id}>
        <NotificationCardItem
          notification={notification}
          handleEditClick={handleEditClick}
          handleDelete={handleDelete}
          handleSend={handleSend}
          handlePreview={handlePreview}
        />
      </Grid>
    ));
  }
};

export default NotificationsGrid;