// src/pages/verifyEmailPage.js

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper } from '@mui/material';
import authenticationService from '../services/authenticationService';
import EmailIcon from '@mui/icons-material/Email';

function VerifyEmailPage() {
    const { token } = useParams();
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    // Remove the useEffect to prevent automatic verification
    // useEffect(() => {
    //     if (token) {
    //         handleVerifyEmail();
    //     } else {
    //         setMessage('No verification token provided.');
    //     }
    // }, [token]);

    const handleVerifyEmail = async () => {
        try {
            const result = await authenticationService.verifyEmail(token);
            if (result.success)
                setMessage("Email verified successfully.");
            else
                setMessage('Email verification failed.');
        } catch (error) {
            setMessage('Invalid or expired verification token.');
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        handleVerifyEmail();
        handleClose();
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Paper elevation={3} sx={{ padding: 3, width: '90%', maxWidth: 400 }}>
                <Box display="flex" justifyContent="center" mb={2}>
                    <EmailIcon sx={{ fontSize: 150 }} color="primary" />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={handleOpen}
                >
                    Verify Email
                </Button>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    {message}
                </Typography>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Confirm Verification</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please confirm that you wish to verify your email address. Are you certain you initiated this action?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            No
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Box>
    );
}

export default VerifyEmailPage;