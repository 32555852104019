// src/pages/MyAccountPage.js

import React, { useState, useEffect } from "react";

import { Box, Button, Container, Grid, Typography, TextField, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import authenticationService from "../services/authenticationService";
import { USER_TYPE } from "../services/directoryService";
import useUserStore from "../stores/userStore";

const MyAccountPage = () => {
    const navigate = useNavigate();
    const userInfo = useUserStore((state) => state.userInfo);
    const setUserInfo = useUserStore((state) => state.setUserInfo);
    const [isEditing, setIsEditing] = useState(false);
    const [editedFirstName, setEditedFirstName] = useState("");
    const [editedLastName, setEditedLastName] = useState("");
    
    const [userRole, setUserRole] = useState("Unknown");
    
    useEffect(() => {
        if (userInfo) {
            if (userInfo.userType === USER_TYPE.STUDENT) {
                setUserRole("Student");
            } else if (userInfo.userType === USER_TYPE.TEACHER) {
                setUserRole("Teacher");
            } else if (userInfo.userType === USER_TYPE.STAFF) {
                setUserRole("Staff");
            } else if (userInfo.userType === USER_TYPE.PARENT) {
                setUserRole("Parent");
            } else {
                setUserRole("Unknown");
            }
            console.log("User role:", userRole);
        }
    }, [userInfo]);
    
    const handleEdit = () => {
        setIsEditing(true);
        setEditedFirstName(userInfo.firstName);
        setEditedLastName(userInfo.lastName);
    };

    const handleSave = async () => {
        try {
            const updatedInfo = await authenticationService.updateUserInfo({
                given_name: editedFirstName,
                family_name: editedLastName,
            });
            useUserStore.getState().fetchUserInfo();
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update user info:", error);
        }
    };

    const handleLogout = () => {
        authenticationService.logout();
    };
    
    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" align="center" gutterBottom>My Account</Typography>
                {userInfo ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ padding: 2 }}>
                                <Typography variant="h6" gutterBottom>User Information</Typography>
                                {isEditing && userRole === "Parent" ? (
                                    <>
                                        <TextField
                                            label="First Name"
                                            value={editedFirstName}
                                            onChange={(e) => setEditedFirstName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Last Name"
                                            value={editedLastName}
                                            onChange={(e) => setEditedLastName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                            sx={{ mt: 2 }}
                                        >
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body1">
                                            <strong>Fullname:</strong> {userInfo.firstName} {userInfo.lastName}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Email:</strong> {userInfo.upn}
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span><strong>Role:</strong> {userRole}</span>
                                            {userRole === "Parent" && (
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleEdit}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </Typography>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLogout}
                                fullWidth
                            >
                                Logout
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1" align="center">Loading user information...</Typography>
                )}
            </Box>
        </Container>
    );
}

export default MyAccountPage;