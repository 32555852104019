
import React from 'react';
import { AppBar, Toolbar, Typography, Box, Tab, Tabs, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const DirectoryHeader = ({ selectedType, onTabChange, searchQuery, onSearchChange }) => (
  <AppBar position="sticky" color="primary" elevation={0}>
    <Toolbar sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', px: 1, py: 1 }}>
      <Typography variant="h6" sx={{ alignSelf: 'center', mb: 1 }}>
        Directory
      </Typography>
      <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
        <TextField
          placeholder="Search contacts..."
          variant="outlined"
          size="small"
          fullWidth
          value={searchQuery}
          onChange={onSearchChange}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: 1,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Tabs
        value={selectedType}
        onChange={onTabChange}
        variant="fullWidth"
        textColor="inherit"
        indicatorColor="secondary"
        sx={{ mt: 1 }}
      >
        <Tab label="Students" value="students" />
        <Tab label="Employees" value="employees" />
        <Tab label="Parents" value="parents" />
      </Tabs>
    </Toolbar>
  </AppBar>
);

export default DirectoryHeader;