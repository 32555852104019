// src/pages/MiscPage.js

import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

// Importing MUI Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FolderIcon from '@mui/icons-material/Folder';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import authenticationService from '../services/authenticationService';
import useUserStore from '../stores/userStore';

const MiscPage = () => {
    const { userInfo } = useUserStore();
    const [links, setLinks] = useState([]);

    // Define the list of links with their respective icons and paths
    const userLinks = [
        { text: 'My Account', icon: <AccountCircleIcon />, path: '/profile' },
        { text: 'About', icon: <InfoIcon />, path: '/about' },
    ];

    const adminLinks = [
        { text: 'Manage Notifications', icon: <FeedbackIcon />, path: '/notificationsadmin/manage' },
        { text: 'Manage Files', icon:  <FolderIcon />,path: '/files' },
        { text: 'QR Scanner', icon: <QrCodeScannerIcon />, path: '/qrscanner' },
    ];

    useEffect(() => {
        if (userInfo === null) {
            return;
        }
        // Update menu links based on user type
        if (userInfo.is_admin) {
            // Show both user and admin links
            setLinks(adminLinks.concat(userLinks));
        } else {
            setLinks(userLinks);
        }
    }
    , [userInfo]);


    // Logout handler
    const handleLogout = () => {
        // Redirect to the logout endpoint
        authenticationService.logout();
    };

    return (
        <Box>
            <List>
                {links.map((link, index) => (
                    <React.Fragment key={link.text}>
                        <ListItem button component={Link} to={link.path}>
                            <ListItemIcon>
                                {link.icon}
                            </ListItemIcon>
                            <ListItemText primary={link.text} />
                        </ListItem>
                        {index < links.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
                <Divider />
                <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Box>
    );
};

export default MiscPage;
