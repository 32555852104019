// NotificationPreview.js
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Fab, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getNotificationMessageById } from '../../services/notificationService';

const NotificationPreview = ({ open, handleClose, notificationId }) => {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        if (open && notificationId) {
            getNotificationMessageById(notificationId).then((data) => {
                setHtmlContent(data.html_message);
            });
        }
    }, [open, notificationId]);

    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <Box sx={{ position: 'relative', height: '100%' }}>
                <Fab
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        backgroundColor: 'primary.main',
                        color: 'white',
                        boxShadow: 3,
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        zIndex: 1,
                    }}
                    size="small"
                >
                    <CloseIcon />
                </Fab>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default NotificationPreview;