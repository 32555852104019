// src/pages/DirectoryPage.js
import React, { useState, useMemo, useCallback } from "react";
import { useDirectoryStore } from "../services/directoryService";
import {
  Box,
  Typography,
  CircularProgress
} from '@mui/material';
import { FixedSizeList } from 'react-window';

import {
  UserDetailsDialog,
  DirectoryHeader,
  SortControls,
  UserListItem
} from "../components/directory";

import { Button } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

const ITEM_HEIGHT = 64; // Approximate height of one ListItem including Divider

const DirectoryPage = () => {
  const {
    students,
    employees,
    parents,
    loading,
    error,
    refreshUsers
  } = useDirectoryStore();

  const [selectedType, setSelectedType] = useState('students');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortCriterion, setSortCriterion] = useState('givenName-asc');

  // State for dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUPN, setSelectedUPN] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedType(newValue);
    setSearchQuery('');
  };

  const currentUsers = useMemo(() => {
    switch (selectedType) {
      case 'students':
        return students;
      case 'employees':
        return employees;
      case 'parents':
        return parents;
      default:
        return [];
    }
  }, [students, employees, parents, selectedType]);

  const filteredUsers = useMemo(() => {
    if (!currentUsers) return [];
    if (searchQuery.trim() === '') return currentUsers;

    const lowerQ = searchQuery.toLowerCase();
    return currentUsers.filter(user =>
      (user.givenName && user.givenName.toLowerCase().includes(lowerQ)) ||
      (user.sn && user.sn.toLowerCase().includes(lowerQ)) ||
      (user.userPrincipalName && user.userPrincipalName.toLowerCase().includes(lowerQ)) ||
      (user.upn && user.upn.toLowerCase().includes(lowerQ)) // For parents who have 'upn' field
    );
  }, [currentUsers, searchQuery]);

  const sortedUsers = useMemo(() => {
    const [field, direction] = sortCriterion.split('-');
    const sorted = [...filteredUsers].sort((a, b) => {
      const valA = (a[field] || '').toLowerCase();
      const valB = (b[field] || '').toLowerCase();
      if (valA < valB) return direction === 'asc' ? -1 : 1;
      if (valA > valB) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [filteredUsers, sortCriterion]);

  const handleItemClick = (user) => {
    // user might have userPrincipalName or upn depending on type
    const upn = user.userPrincipalName || user.upn;
    setSelectedUPN(upn);
    setDialogOpen(true);
  };

  const renderRow = useCallback(({ index, style }) => {
    const user = sortedUsers[index];
    if (!user) return null;

    const isLast = (index === sortedUsers.length - 1);

    return (
      <div style={style}>
        <UserListItem
          user={user}
          onClick={handleItemClick}
          isLast={isLast}
        />
      </div>
    );
  }, [sortedUsers]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">Error loading users</Typography>
        <Button startIcon={<RefreshIcon />} onClick={refreshUsers} variant="contained" sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  const itemCount = sortedUsers.length;

  return (
    <>
      <Box sx={{ bgcolor: '#f5f5f5', height: '100vh', display: 'flex', flexDirection: 'column', overflow: "hidden" }}>
        {/* Directory Header with Search and Tabs */}
        <DirectoryHeader
          selectedType={selectedType}
          onTabChange={handleTabChange}
          searchQuery={searchQuery}
          onSearchChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Controls for Sorting and Refresh */}
        <SortControls
          sortCriterion={sortCriterion}
          onSortChange={(e) => setSortCriterion(e.target.value)}
          onRefresh={refreshUsers}
        />

        {/* Virtualized List */}
        <Box
          sx={{
            flex: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {itemCount === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
              <Typography variant="body1" color="textSecondary">
                No contacts found.
              </Typography>
            </Box>
          ) : (
            <FixedSizeList
              height={window.innerHeight - 200} // Adjust based on your layout
              width="100%"
              itemCount={itemCount}
              itemSize={ITEM_HEIGHT}
              style={{ backgroundColor: 'white' }}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </Box>
      </Box>

      {/* User Details Dialog */}
      <UserDetailsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        upn={selectedUPN}
        userType={selectedType.slice(0, -1)}
      // 'students' => 'student', 'employees' => 'employee', 'parents' => 'parent'
      />
    </>
  );
};

export default DirectoryPage;
