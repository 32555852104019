// src/pages/notificationCategoryPage.js
import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Paper,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
} from "@mui/material";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from "@mui/icons-material";
import {
    getNotificationCategories,
    createNotificationCategory,
    updateNotificationCategory,
    deleteNotificationCategory
} from "../services/notificationService";
import { BOTTOM_BAR_HEIGHT } from "../components/generic/BottomBar";


const NotificationCategoryPage = () => {
    const [notificationCategories, setNotificationCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");

    useEffect(() => {
        fetchNotificationCategories();
    }, []);

    const fetchNotificationCategories = async () => {
        try {
            const response = await getNotificationCategories();
            setNotificationCategories(response);
            console.log('Notification categories:', response);
        } catch (error) {
            console.error("Error fetching notification categories:", error);
        }
    };

    // Handle the click event for the "Add" button
    const handleAddClick = () => {
        setCategoryName("");
        setCategoryDescription("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditOpen(false);
        setCurrentCategory(null);
    };

    const handleAddCategory = async () => {
        if (categoryName.trim() === "") {
            // Optionally, add validation or display an error message
            return;
        }

        try {
            const newCategory = await createNotificationCategory(categoryName, categoryDescription);
            setNotificationCategories((prevCategories) => [...prevCategories, newCategory]);
            console.log('Added new category:', newCategory);
            handleClose();
        } catch (error) {
            console.error("Error adding new category:", error);
            // Optionally, handle the error (e.g., show a notification)
        }
    };

    const handleEditClick = (category) => {
        setCurrentCategory(category);
        setCategoryName(category.name);
        setCategoryDescription(category.description);
        setEditOpen(true);
    };

    const handleUpdateCategory = async () => {
        if (categoryName.trim() === "") {
            // Optionally, add validation or display an error message
            return;
        }

        try {
            const updatedCategory = await updateNotificationCategory(currentCategory.id, categoryName, categoryDescription);
            setNotificationCategories((prevCategories) =>
                prevCategories.map((cat) =>
                    cat.id === updatedCategory.id ? updatedCategory : cat
                )
            );
            console.log('Updated category:', updatedCategory);
            handleClose();
        } catch (error) {
            console.error("Error updating category:", error);
            // Optionally, handle the error (e.g., show a notification)
        }
    };

    const handleDeleteClick = async (categoryId) => {
        try {
            await deleteNotificationCategory(categoryId);
            setNotificationCategories((prevCategories) =>
                prevCategories.filter((cat) => cat.id !== categoryId)
            );
            console.log('Deleted category with ID:', categoryId);
        } catch (error) {
            console.error("Error deleting category:", error);
            // Optionally, handle the error (e.g., show a notification)
        }
    };

    return (
        <Container sx={
            { paddingBottom: BOTTOM_BAR_HEIGHT,
            paddingTop: 2,}
        }
            >
            <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{
                    fontFamily: 'Roboto, sans-serif', // Modern font
                    fontWeight: 'bold', // Bold text
                    color: '#333', // Modern color
                }}
            >
                Notification Categories
            </Typography>
            <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <List>
                    {notificationCategories.map((category, index) => (
                        <React.Fragment key={category.id}>
                            <ListItem>
                                <ListItemText
                                    primary={category.name}
                                    secondary={category.description}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() => handleEditClick(category)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDeleteClick(category.id)}
                                        sx={{ ml: 1 }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {index < notificationCategories.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}

                </List>
            </Paper>
            <Fab
                color="primary"
                aria-label="add"
                sx={{
                    position: 'fixed',
                    bottom: 16 + BOTTOM_BAR_HEIGHT,
                    right: 16,
                }}
                onClick={handleAddClick}
            >
                <AddIcon />
            </Fab>

            {/* Add Category Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Category</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Category Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Category Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={categoryDescription}
                        onChange={(e) => setCategoryDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddCategory} variant="contained" color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Category Dialog */}
            <Dialog open={editOpen} onClose={handleClose}>
                <DialogTitle>Edit Category</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Category Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Category Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={categoryDescription}
                        onChange={(e) => setCategoryDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpdateCategory} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default NotificationCategoryPage;
