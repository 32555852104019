// src/components/loginPage/RegisterForm.js

import React, { useState, useRef, useEffect } from 'react';
import { Button, TextField, Box, Typography, Fade, Alert } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import authenticationService from '../../services/authenticationService';

const RegisterForm = ({ onCancel }) => {
    const [email, setEmail] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // For password confirmation
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const emailRef = useRef(null);

    const handleRegister = async () => {
        // Reset messages
        setError('');
        setSuccessMessage('');

        // Basic validation
        if (!email || !givenName || !familyName || !phoneNumber || !password || !confirmPassword) {
            setError('Please fill in all the fields.');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const response = await authenticationService.registerParent(
                email,
                givenName,
                familyName,
                phoneNumber,
                password,
            );

            if (response.success) {
                setSuccessMessage(response.message);
                // Optionally, navigate or reset form after successful registration
            } else {
                setError(response.message || 'Registration failed. Please try again.');
            }
        } catch (error) {
            console.error('Registration error:', error);
            setError(error.message || 'An error occurred during registration. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Focus on the email field when the form appears
    useEffect(() => {
        if (emailRef.current) {
            emailRef.current.focus();
        }
    }, []);

    return (
        <Fade in={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    // padding: '20px',
                    // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    // borderRadius: '10px',
                    backgroundColor: 'transparent',
                    width: '100%',
                }}
            >
                <Typography variant="h6" align="center">
                    Parent Registration
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    inputRef={emailRef}
                    fullWidth
                />
                <TextField
                    label="Given Name"
                    variant="outlined"
                    value={givenName}
                    onChange={(e) => setGivenName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Family Name"
                    variant="outlined"
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRegister}
                    fullWidth
                    disabled={loading}
                    sx={{
                        padding: '12px 24px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        textTransform: 'none',
                    }}
                >
                    {loading ? 'Registering...' : 'Register'}
                </Button>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2">
                        Already have an account?{' '}
                        <Link to="#" onClick={onCancel} style={{ textDecoration: 'none', color: '#1976d2' }}>
                            Back to Login
                        </Link>
                    </Typography>
                </Box>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={onCancel}
                    fullWidth
                    sx={{
                        textTransform: 'none',
                    }}
                >
                    Cancel
                </Button>
            </Box>
        </Fade>
    );
};

export default RegisterForm;
