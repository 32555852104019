
// src/components/notificationManagementPage/NotificationCardItem.js

import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Divider,
  Box,
  Chip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';

const NotificationCardItem = ({
  notification,
  handleEditClick,
  handleDelete,
  handleSend,
  handlePreview,
}) => {
  const { id, base64Icon, title, short_message, category, createdAt } = notification;

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        avatar={
          base64Icon ? (
            <Box
              component="img"
              src={base64Icon}
              alt={title ? `${title} icon` : 'Notification icon'}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ''; // Or set to a default image URL
              }}
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
              }}
            />
          ) : null
        }
        title={title}
        subheader={new Date(createdAt).toLocaleString()}
        action={category && <Chip label={category} variant="outlined" />}
      />
      <Divider />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="body2" gutterBottom>
          {short_message}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton aria-label="edit notification" onClick={() => handleEditClick(notification)}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete notification" color="error" onClick={() => handleDelete(id)}>
          <DeleteIcon />
        </IconButton>
        <IconButton
          aria-label="send notification"
          color="primary"
          onClick={() => handleSend(notification)}
        >
          <SendIcon />
        </IconButton>
        <IconButton aria-label="preview notification" onClick={() => handlePreview(notification.id)}>
          <VisibilityIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default NotificationCardItem;