// src/components/generic/NotificationRegister.js
import React, { useEffect, useState } from 'react';
import notificationService from '../../services/notificationService';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const NativeButton = styled(Button)(({ theme }) => ({
    minWidth: 'auto',
    padding: theme.spacing(0.5, 1),
    textTransform: 'none',
    fontSize: '0.875rem',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent',
        opacity: 0.8,
    },
    boxShadow: 'none',
}));

const NotificationRegisterWorker = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [snackbarKey, setSnackbarKey] = useState(0);
    const [isRegistering, setIsRegistering] = useState(false);
    const [snackbarDuration, setSnackbarDuration] = useState(15000);

    useEffect(() => {
        const checkNotificationPermission = () => {
            const permission = notificationService.getNotificationPermissionStatus();
            if (permission === 'granted') {
                registerPushNotifications(false);
            } else if (permission === 'denied') {
                setSnackbarMessage('Notifications are blocked. Please enable them in settings.');
                setSnackbarSeverity('warning');
                setSnackbarDuration(6000);
                openSnackbar();
            } else {
                setSnackbarMessage('Push notifications are not registered.');
                setSnackbarSeverity('info');
                setSnackbarDuration(6000);
                openSnackbar();
            }
        };

        checkNotificationPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openSnackbar = () => {
        setSnackbarKey((prev) => prev + 1);
        setSnackbarOpen(true);
    };

    const registerPushNotifications = async (isUserInitiated = false) => {
        try {
            await notificationService.registerPushNotification();
            if (isUserInitiated) {
                setSnackbarMessage('Push notifications registered successfully.');
                setSnackbarSeverity('success');
                setSnackbarDuration(6000);
                openSnackbar();
            }
        } catch (error) {
            console.error('Error registering push notifications:', error);
            setSnackbarMessage('Failed to register push notifications.');
            setSnackbarSeverity('error');
            setSnackbarDuration(6000);
            openSnackbar();
        }
    };

    const handleRegisterClick = async () => {
        setIsRegistering(true);
        setSnackbarMessage('Registering push notifications...');
        setSnackbarSeverity('info');
        setSnackbarDuration(null);
        openSnackbar();

        try {
            const permission = await notificationService.registerPushNotification();
            if (permission) {
                setSnackbarMessage('Push notifications registered successfully.');
                setSnackbarSeverity('success');
            } else {
                setSnackbarMessage('Notification permission denied.');
                setSnackbarSeverity('warning');
            }
        } catch (error) {
            console.error('Error requesting notification permission:', error);
            setSnackbarMessage('An error occurred while requesting permission.');
            setSnackbarSeverity('error');
        } finally {
            setIsRegistering(false);
            setSnackbarDuration(6000);
            openSnackbar();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const getSnackbarAction = () => {
        const permission = notificationService.getNotificationPermissionStatus();
        if (permission === 'default') {
            return isRegistering ? (
                <CircularProgress size={24} />
            ) : (
                <NativeButton color="secondary" size="small" onClick={handleRegisterClick}>
                    REGISTER
                </NativeButton>
            );
        }
        return null;
    };

    return (
        <>
            <Snackbar
                key={snackbarKey}
                open={snackbarOpen}
                autoHideDuration={snackbarDuration}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{
                    mb: 8,
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    action={getSnackbarAction()}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default NotificationRegisterWorker;