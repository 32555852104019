import React, { useEffect, useState } from 'react';
import parentRelationService from '../services/parentRelationService';
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Snackbar,
    Alert,
    Fab,
    CircularProgress,
    Avatar,
    Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import QRReader from 'react-qr-scanner';

function MyChildrenPage() {
    const [children, setChildren] = useState([]);
    const [error, setError] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pairingCode, setPairingCode] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false); // Loading state

    // Fetch the list of children on component mount
    useEffect(() => {
        fetchChildren();
    }, []);

    const fetchChildren = () => {
        setLoading(true); // Start loading
        parentRelationService.getStudents()
            .then((data) => {
                if (Array.isArray(data)) {
                    // Map the API data to a more usable format
                    const mappedChildren = data.map(child => ({
                        id: child.userPrincipalName,
                        name: `${child.givenName} ${child.sn}`,
                        email: child.userPrincipalName,
                        // Add more fields if available, e.g., age
                    }));
                    setChildren(mappedChildren);
                } else if (data === "") {
                    setChildren([]); // Treat empty string as no children
                } else {
                    console.error("Invalid data format:", data);
                    setError("Failed to load children list.");
                }
            })
            .catch((error) => {
                console.error("Error fetching children:", error);
                setError("An error occurred while fetching children.");
            })
            .finally(() => {
                setLoading(false); // End loading
            });
    };

    const handleOpenDialog = () => {
        setPairingCode('');
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleBindChild = async () => {
        if (!pairingCode.trim()) {
            setSnackbar({ open: true, message: 'Pairing code cannot be empty.', severity: 'error' });
            return;
        }
        try {
            await parentRelationService.bindStudent(pairingCode.trim());
            setSnackbar({ open: true, message: 'Child successfully bound!', severity: 'success' });
            setDialogOpen(false);
            fetchChildren();
        } catch (err) {
            console.error(err);
            setSnackbar({ open: true, message: 'Failed to bind child. Please check the pairing code.', severity: 'error' });
        }
    };

    const handleUnbindChild = async (childId) => {
        try {
            await parentRelationService.unbindStudent(childId);
            setSnackbar({ open: true, message: 'Child successfully unbound!', severity: 'success' });
            fetchChildren();
        } catch (err) {
            console.error(err);
            setSnackbar({ open: true, message: 'Failed to unbind child.', severity: 'error' });
        }
    };

    return (
        <Container sx={{ paddingY: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                My Children
            </Typography>

            {/* Error Handling */}
            {error ? (
                <Typography color="error">{error}</Typography>
            ) : loading ? (
                // Loading Indicator
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <List>
                    {children.length > 0 ? (
                        children.map((child) => (
                            <ListItem key={child.id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                                        {child.name.charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={child.name}
                                    secondary={child.email}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title="Unbind Child">
                                        <IconButton 
                                            edge="end" 
                                            aria-label={`Unbind ${child.name}`} 
                                            onClick={() => handleUnbindChild(child.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    ) : (
                        <Typography>You have no children linked.</Typography>
                    )}
                </List>
            )}

            {/* Button to Open Bind Dialog */}
            <Fab
                color="primary"
                aria-label="Add Child"
                onClick={handleOpenDialog}
                sx={{
                    position: 'fixed',
                    bottom: 75,
                    right: 16,
                }}
                variant='extended'
            >
                <AddIcon sx={{ marginRight: 1 }} />
                Add Child
            </Fab>

            {/* Bind Child Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="bind-child-dialog-title"
            >
                <DialogTitle id="bind-child-dialog-title">
                    Bind a New Child
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <QRReader 
                        onScan={(data) => {
                            if (data) {
                                setPairingCode(data.text);
                            }
                        }}
                        onError={(error) => {
                            console.error(error);
                            setError('Camera error. Please ensure camera permissions are granted.');
                        }}
                        style={{ width: '100%', height: '100%' }}
                        facingMode="rear"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pairing-code"
                        label="Pairing Code"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={pairingCode}
                        onChange={(e) => setPairingCode(e.target.value)}
                        helperText="Enter the pairing code provided by your child."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBindChild} color="primary" variant="contained">
                        Bind
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity} 
                    sx={{ width: '100%' }}
                    variant="filled"
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default MyChildrenPage;
