import { createTheme } from '@mui/material/styles';

// Modern Bright Pink Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#FF4081', // Modern bright pink
      light: '#FF79A5', // Light pink
      dark: '#C60055', // Dark pink
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F48FB1', // Lighter pink
      contrastText: '#000000',
    },
    background: {
      default: '#FAFAFA', // Light gray background
      paper: '#FFFFFF', // White for cards
    },
    text: {
      primary: '#333333', // Dark gray text
      secondary: '#555555',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF4081',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#C60055',
          },
          borderRadius: '8px',
          padding: '10px 20px',
          boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
          borderRadius: '12px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF4081',
          color: '#ffffff',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#333333',
        },
      },
    },
  },
});

export default theme;
