// src/pages/QRScannerPage.js
import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Alert,
} from '@mui/material';
import QRReader from 'react-qr-scanner';
import qridService from '../services/qridService';

const QRScannerPage = () => {
    const [scannedData, setScannedData] = useState(null);
    const [scannedUserInfo, setScannedUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (scannedData) {
            console.log('Scanned Data:', scannedData);
            try {
                const payload = scannedData.text;
                setLoading(true);
                qridService.verifyQRid(payload)
                    .then(result => {
                        console.log('QR ID Verification Result:', result);
                        setScannedUserInfo(result);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error('Verification Error:', err);
                        setError('Failed to verify QR ID. Please try again.');
                        setLoading(false);
                    });
            } catch (error) {
                console.error('Error parsing scanned data:', error);
                setError('Invalid QR data. Please scan a valid QR code.');
            }
        }
    }, [scannedData]);

    const handleClose = () => {
        setScannedUserInfo(null);
        setError(null);
        setScannedData(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            minHeight="100vh"
            bgcolor="#f5f5f5"
            p={2}
        >
            <Typography variant="h4" gutterBottom>
                QR Scanner
            </Typography>
            <Box
                width={{ xs: '100%', sm: '500px' }}
                height={{ xs: '300px', sm: '500px' }}
                mb={2}
                borderRadius={2}
                overflow="hidden"
                boxShadow={3}
            >
                <QRReader
                    onScan={(data) => {
                        if (data) {
                            setScannedData(data);
                        }
                    }}
                    onError={(error) => {
                        console.error(error);
                        setError('Camera error. Please ensure camera permissions are granted.');
                    }}
                    style={{ width: '100%', height: '100%' }}
                    facingMode="rear"
                />
            </Box>

            {loading && <CircularProgress />}

            <Dialog open={scannedUserInfo !== null || error !== null} onClose={handleClose}>
                <DialogTitle>Scanned User Info</DialogTitle>
                <DialogContent>
                    {loading && (
                        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                            <CircularProgress />
                        </Box>
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                    {scannedUserInfo && (
                        <List>
                            <ListItem>
                                <ListItemText primary="Verified" secondary={scannedUserInfo.verified ? 'Yes' : 'No'} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="User Principal Name (UPN)" secondary={scannedUserInfo.upn} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="First Name" secondary={scannedUserInfo.firstName} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Last Name" secondary={scannedUserInfo.lastName} />
                            </ListItem>
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default QRScannerPage;
