import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom'; // Add this import
import NotificationList from '../components/notificationPage/NotificationList';
import NotificationPreview from '../components/notificationPage/NotificationPreview'; // Ensure NotificationPreview is imported
import { getNotificationsForUser, getNotificationCategories } from '../services/notificationService';
import useFCMListener from '../hooks/useFCMListener';
import debounce from 'lodash.debounce';
import nativeService from '../services/nativeService';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [inputValue, setInputValue] = useState(''); // New state for immediate input
    // const [searchQuery, setSearchQuery] = useState(''); // Removed searchQuery state
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const categoriesRef = useRef(selectedCategories);
    const { id } = useParams(); // Get the notification ID from URL
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewId, setPreviewId] = useState(null);

    useEffect(() => {
        // Fetch categories
        getNotificationCategories().then((data) => {
            setCategories(data);
        });
    }, []);

    useEffect(() => {
        categoriesRef.current = selectedCategories;
    }, [selectedCategories]);

    const fetchNotifications = useCallback(async (page, query) => {
        const data = await getNotificationsForUser(page, 10, selectedCategories, query);
        // Create a mapping of messages by their ID
        const messagesById = data.messages.reduce((acc, message) => {
            acc[message.id] = message;
            return acc;
        }, {});
        // Build the notifications array using occurrences
        const newNotifications = data.occurrences.map((occurrence) => {
            const message = messagesById[occurrence.message_id];
            return {
                ...message,
                timestamp: occurrence.timestamp,
            };
        });
        if (page > 1 && categoriesRef.current === selectedCategories) {
            setNotifications((prev) => [...prev, ...newNotifications]);
        } else {
            setNotifications(newNotifications);
        }
        setHasMore(data.pagination.hasNextPage);
    }, [selectedCategories]);

    useFCMListener((payload) => {
        console.log('Message received. ', payload);
        // Play notification sound, vibrate, and shake screen
        nativeService.notificationSound();
        nativeService.vibrate();
        nativeService.shakeScreen();
        // Refresh notifications when a new message is received (Re-fetch all notifications)
        setNotifications([]);
        setPage(1);
        setHasMore(true);
        fetchNotifications(1, inputValue);
    });

    useEffect(() => {
        fetchNotifications(page, inputValue);
    }, [page, fetchNotifications]);

    useEffect(() => {
        setNotifications([]);
        setPage(1);
        setHasMore(true);
        fetchNotifications(1, inputValue);
    }, [selectedCategories, fetchNotifications]);

    useEffect(() => {
        if (id) {
            setPreviewId(id);
            setPreviewOpen(true);
        }
    }, [id]);

    const debouncedSearch = useCallback(
        debounce((query) => {
            setNotifications([]);
            setPage(1);
            setHasMore(true);
            fetchNotifications(1, query);
        }, 300),
        [fetchNotifications]
    );

    const handleSearchChange = (e) => {
        setInputValue(e.target.value); // Update immediate input value
        debouncedSearch(e.target.value); // Debounce fetching notifications
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategories((prev) =>
            prev.includes(categoryId)
                ? prev.filter((id) => id !== categoryId)
                : [...prev, categoryId]
        );
    };

    const handleScroll = (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleClosePreview = () => {
        setPreviewOpen(false);
        setPreviewId(null);
    };

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <Box
        pl={1}
        pr={1}
        pt={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >

            <TextField
                placeholder="Search notifications"
                value={inputValue} // Bind to inputValue for instant updates
                onChange={handleSearchChange}
                sx={{ marginBottom: '16px', width: '100%' }}
            />
            <FormGroup row>
                {categories.map((category) => (
                    <FormControlLabel
                        key={category.id}
                        control={
                            <Checkbox
                                checked={selectedCategories.includes(category.id)}
                                onChange={() => handleCategoryChange(category.id)}
                            />
                        }
                        label={category.name}
                    />
                ))}
            </FormGroup>
            <Box
                sx={{ overflowY: 'auto', height: '80vh', width: '100%' }}
                onScroll={handleScroll}
            >
                <NotificationList notifications={notifications} />
            </Box>
            <NotificationPreview
                open={previewOpen}
                handleClose={handleClosePreview}
                notificationId={previewId}
            />
        </Box>
    );
}

export default NotificationsPage;
