// services/authenticationService.js

import { API_URL } from "../config";
import axios from "axios";
import useUserStore from "../stores/userStore";

const CACHE_KEY = 'userInfo';
const CACHE_TIMESTAMP_KEY = 'userInfoTimestamp';
const CACHE_DURATION = 3600 * 1000; // 3600 seconds in milliseconds

async function isLoggedIn() {
    try {
        const response = await axios.get(`${API_URL}/api/auth/user`);
        return true;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return false;
        } else {
            console.error(error);
            return false;
        }
    }
}

async function getUserInfo() {
    const { userInfo, setUserInfo } = useUserStore.getState();

    if (userInfo) {
        return userInfo;
    }

    try {
        const fetchedUserInfo = await getUserInfoNoCache();
        setUserInfo(fetchedUserInfo);
        return fetchedUserInfo;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

async function getUserInfoNoCache() {
    try {
        const response = await axios.get(`${API_URL}/api/auth/user`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

function getUserType() {
    const userInfo = localStorage.getItem(CACHE_KEY);
    if (userInfo) {
        return JSON.parse(userInfo).userType;
    }
    return null;
}

async function logout() {
    // Clear user info in store
    useUserStore.getState().clearUserInfo();
    // Unsubscribe from push notifications
    let notificationService = require('./notificationService').default;
    await notificationService.unsubscribe();
    window.location.href = `${API_URL}/api/auth/logout`;
}

function login() {
    window.location.href = `${API_URL}/api/auth/login`;
}

async function loginParent(username, password) {
    try {
        const response = await axios.post('/api/auth/login/parent', {
            username: username,
            password: password
        });
        console.log('Response', response);
        if (response.status === 200) {
            console.log('Login successful');
            window.location.href = '/';
            return { success: true };
        } else {
            return { success: false, message: 'Login failed with status: ' + response.status };
        }
    } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.status === 401) {
            return { success: false, message: 'Invalid username or password. Please try again.' };
        }
        return { success: false, message: 'An error occurred during login: ' + error.message };
    }
}

async function registerParent(email, givenName, familyName, phoneNumber, password) {
    try {
        const response = await axios.post(`${API_URL}/api/auth/register/parent`, {
            email,
            givenName,
            familyName,
            phoneNumber,
            password,
        });
        return { success: true, message: response.data };
    } catch (error) {
        console.error('Registration error:', error);
        return { success: false, message: error.response?.data || 'Registration failed.' };
    }
}

async function updateUserInfo(userInfo) {
  try {
    const response = await fetch('/api/auth/user', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userInfo),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update user info.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating user info:', error);
    throw error;
  }
}

async function verifyEmail(token) {
    try {
        const response = await axios.post(`${API_URL}/api/auth/verify-email`, { token });
        return { success: true, message: response.data };
    } catch (error) {
        console.error('Email verification error:', error);
        return { success: false, message: error.response?.data || 'Email verification failed.' };
    }
}

const authenticationService = {
    isLoggedIn,
    getUserInfo,
    getUserInfoNoCache,
    logout,
    login,
    loginParent,
    registerParent,
    getUserType,
    updateUserInfo,
    verifyEmail,
};

export default authenticationService;