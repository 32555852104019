
// src/components/SendNotificationDialog.js
import React, { useState, useEffect, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    MenuItem,
    Grid,
    Typography,
    Select,
    InputLabel,
    FormControl,
    Paper,
    Box,
    Autocomplete,
    CircularProgress,
    useTheme
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { sendNotificationToTargets, getNotificationGroupList } from '../../services/notificationService';
import { useDirectoryStore } from "../../services/directoryService";
import { FixedSizeList } from 'react-window';
import { styled } from '@mui/material/styles';

const targetScopes = [
    { value: 'self', label: 'Self' },
    { value: 'parent', label: 'Parent' },
    { value: 'both', label: 'Both' }
];

const targetTypes = [
    { value: 'upn', label: 'User' },
    { value: 'group', label: 'Group' },
    { value: 'everyone', label: 'Everyone' },
    { value: 'all_employees', label: 'All Employees' },
    { value: 'all_parents', label: 'All Parents' },
    { value: 'all_students', label: 'All Students' }
];

// Virtualization List Component
const LISTBOX_PADDING = 8;

const StyledListbox = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    '& ul': {
        padding: 0,
        margin: 0,
    },
}));

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const VirtualizedListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 48;

    const height = Math.min(8, itemCount) * itemSize + 2 * LISTBOX_PADDING;

    return (
        <StyledListbox ref={ref} {...other}>
            <FixedSizeList
                height={height}
                itemCount={itemCount}
                itemSize={itemSize}
                width="100%"
                outerElementType={React.forwardRef((props, ref) => <div ref={ref} {...props} />)}
                innerElementType="ul"
                itemData={children}
            >
                {renderRow}
            </FixedSizeList>
        </StyledListbox>
    );
});

const SendNotificationDialog = ({ open, onClose, notification }) => {
    const [targets, setTargets] = useState([
        { type: 'upn', value: '', scope: 'self', userType: 'student' }
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [groupOptions, setGroupOptions] = useState([]);
    const [groupLoading, setGroupLoading] = useState(false);
    const [groupError, setGroupError] = useState(null);

    const { students, employees, parents } = useDirectoryStore();

    const allUsers = useMemo(() => {
        const mappedStudents = students.map(user => ({
            ...user,
            fullName: `${user.givenName} ${user.sn}`,
            userType: 'student'
        }));
        const mappedEmployees = employees.map(user => ({
            ...user,
            fullName: `${user.givenName} ${user.sn}`,
            userType: 'employee'
        }));
        const mappedParents = parents.map(user => ({
            ...user,
            fullName: `${user.givenName} ${user.sn}`,
            userType: 'parent'
        }));
        return [...mappedStudents, ...mappedEmployees, ...mappedParents];
    }, [students, employees, parents]);

    useEffect(() => {
        if (open) {
            fetchGroupList();
        } else {
            setTargets([{ type: 'upn', value: '', scope: 'self', userType: 'student' }]);
            setError(null);
            setGroupOptions([]);
            setGroupError(null);
        }
    }, [open]);

    const fetchGroupList = async () => {
        setGroupLoading(true);
        setGroupError(null);
        try {
            const groups = await getNotificationGroupList();
            setGroupOptions(groups.map(group => group.cn));
        } catch (err) {
            console.error('Error fetching group list:', err);
            setGroupError('Failed to fetch group list.');
        } finally {
            setGroupLoading(false);
        }
    };

    const handleAddTarget = () => {
        setTargets([...targets, { type: 'upn', value: '', scope: 'self', userType: 'student' }]);
    };

    const handleRemoveTarget = (index) => {
        const updatedTargets = [...targets];
        updatedTargets.splice(index, 1);
        setTargets(updatedTargets);
    };

    const handleTargetChange = (index, field, newValue) => {
        const updatedTargets = [...targets];
        updatedTargets[index][field] = newValue;

        if (field === 'type') {
            if (newValue !== 'upn' && newValue !== 'group') {
                updatedTargets[index]['scope'] = '';
                updatedTargets[index]['value'] = '';
                updatedTargets[index]['userType'] = '';
            } else {
                updatedTargets[index]['scope'] = 'self';
                updatedTargets[index]['userType'] = 'student';
                updatedTargets[index]['value'] = '';
            }
        }

        setTargets(updatedTargets);
    };

    const handleUserSelect = (index, event, newValue) => {
        if (newValue) {
            const userIdentifier = newValue.upn || newValue.userPrincipalName || newValue.id || '';
            if (!userIdentifier) {
                console.warn(`Selected user does not have a valid identifier:`, newValue);
            }
            handleTargetChange(index, 'value', userIdentifier);
            handleTargetChange(index, 'userType', newValue.userType || '');

            if (newValue.userType === 'student') {
                handleTargetChange(index, 'scope', 'self');
            } else {
                handleTargetChange(index, 'scope', '');
            }
        } else {
            handleTargetChange(index, 'value', '');
            handleTargetChange(index, 'userType', '');
            handleTargetChange(index, 'scope', '');
        }
    };

    const handleGroupSelect = (index, event, newValue) => {
        if (newValue) {
            handleTargetChange(index, 'value', newValue || '');
            handleTargetChange(index, 'userType', '');
            handleTargetChange(index, 'scope', 'self');
        } else {
            handleTargetChange(index, 'value', '');
            handleTargetChange(index, 'userType', '');
            handleTargetChange(index, 'scope', '');
        }
    };

    const handleSubmit = async () => {
        console.log('Submitting targets:', targets);
        for (const target of targets) {
            if (
                (target.type === 'upn' || target.type === 'group') &&
                !(target.value && target.value.trim())
            ) {
                console.debug('Target:', target, 'is invalid.');
                setError('All target fields must be filled.');
                return;
            }
        }

        setIsSubmitting(true);
        setError(null);

        const messageId = notification.id;
        
        try {
            const targetsList = targets.map((t) => {
                    switch (t.type) {
                        case 'upn':
                            return {
                                type: 'upn',
                                entry: t.value,
                                scope: t.userType === 'student' ? t.scope : ''
                            };
                        case 'group':
                            return {
                                type: 'group',
                                entry: t.value,
                                scope: t.scope
                            };
                        case 'everyone':
                            return {
                                type: 'everyone'
                            };
                        case 'all_employees':
                            return {
                                type: 'all_employees'
                            };
                        case 'all_parents':
                            return {
                                type: 'all_parents'
                            };
                        case 'all_students':
                            return {
                                type: 'all_students'
                            };
                        default:
                            return {};
                    }
                });


            await sendNotificationToTargets(messageId, targetsList);
            onClose();
        } catch (err) {
            console.error('Error sending notification:', err);
            setError('Failed to send notification. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
        if (!isSubmitting) {
            onClose();
            setTargets([{ type: 'upn', value: '', scope: 'self', userType: 'student' }]);
            setError(null);
            setGroupOptions([]);
            setGroupError(null);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Send Notification</DialogTitle>
            <DialogContent>
                {notification && (
                    <Box mb={3}>
                        <Typography variant="h6">{notification.title}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {new Date(notification.createdAt).toLocaleString()}
                        </Typography>
                        <Typography variant="body1" mt={1}>
                            {notification.short_message}
                        </Typography>
                        <Box mt={1} dangerouslySetInnerHTML={{ __html: notification.html_message }} />
                    </Box>
                )}
                {error && (
                    <Typography color="error" variant="body2" gutterBottom>
                        {error}
                    </Typography>
                )}
                <Grid container spacing={2}>
                    {targets.map((target, index) => (
                        <Grid item xs={12} key={index}>
                            <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`type-label-${index}`}>Type</InputLabel>
                                            <Select
                                                labelId={`type-label-${index}`}
                                                value={target.type}
                                                label="Type"
                                                onChange={(e) =>
                                                    handleTargetChange(index, 'type', e.target.value)
                                                }
                                            >
                                                {targetTypes.map((type) => (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        {target.type === 'upn' ? (
                                            <Autocomplete
                                                options={allUsers}
                                                getOptionLabel={(option) => option.fullName}
                                                onChange={(event, newValue) => handleUserSelect(index, event, newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select User"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={target.type === 'upn' && !target.value}
                                                    />
                                                )}
                                                ListboxComponent={VirtualizedListboxComponent}
                                                disableListWrap
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.upn || option.userPrincipalName || option.id}>
                                                        {option.fullName}
                                                    </li>
                                                )}
                                                value={allUsers.find(user => user.upn === target.value || user.userPrincipalName === target.value || user.id === target.value) || null}
                                            />
                                        ) : target.type === 'group' ? (
                                            <Autocomplete
                                                options={groupOptions}
                                                getOptionLabel={(option) => option}
                                                onChange={(event, newValue) => handleGroupSelect(index, event, newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Group"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {groupLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={target.type === 'group' && !target.value}
                                                    />
                                                )}
                                                ListboxComponent={VirtualizedListboxComponent}
                                                disableListWrap
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option}>
                                                        {option}
                                                    </li>
                                                )}
                                                loading={groupLoading}
                                                value={target.value || null}
                                            />
                                        ) : (
                                            <TextField
                                                label="No Selection Needed"
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                value={target.type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        {( (target.type === 'upn' && target.userType === 'student') || target.type === 'group') ? (
                                            <FormControl fullWidth>
                                                <InputLabel id={`scope-label-${index}`}>Scope</InputLabel>
                                                <Select
                                                    labelId={`scope-label-${index}`}
                                                    value={target.scope}
                                                    label="Scope"
                                                    onChange={(e) =>
                                                        handleTargetChange(index, 'scope', e.target.value)
                                                    }
                                                >
                                                    {targetScopes.map((scope) => (
                                                        <MenuItem key={scope.value} value={scope.value}>
                                                            {scope.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <TextField
                                                label="Scope"
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                value={
                                                    target.type === 'upn' && target.userType !== 'student'
                                                        ? 'N/A'
                                                        : target.type !== 'upn' && target.type !== 'group'
                                                            ? 'N/A'
                                                            : ''
                                                }
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        {targets.length > 1 && (
                                            <IconButton
                                                aria-label="remove target"
                                                onClick={() => handleRemoveTarget(index)}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                                {target.type === 'group' && groupError && (
                                    <Typography color="error" variant="body2" mt={1}>
                                        {groupError}
                                    </Typography>
                                )}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddTarget}
                    >
                        Add Target
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={isSubmitting}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Sending...' : 'Send'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendNotificationDialog;