// src/components/generic/TopBar.js

import React from 'react';
import { AppBar, Toolbar, Typography, Box, keyframes } from '@mui/material';

const TOP_BAR_HEIGHT = 56; // Define the height in pixels

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
`;

function TopBar() {
  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={4}
      sx={{
        height: `${TOP_BAR_HEIGHT}px`,
        justifyContent: 'center',
        zIndex: 1300,
      }}
    >
      <Toolbar
        sx={{
          minHeight: `${TOP_BAR_HEIGHT}px !important`, // Override default Toolbar height
          paddingLeft: 2, // Left padding
          paddingRight: 2, // Right padding
          display: 'flex',
          alignItems: 'center', // Vertically center content
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5" // Adjusted variant for smaller size
            align="left"
            gutterBottom={false}
            sx={{
              fontFamily: 'Roboto, sans-serif', // Modern font
              fontWeight: 'bold', // Bold text
              letterSpacing: '1px', // Reduced letter spacing
              background: 'linear-gradient(90deg, #ffe0e9, #ffb3c1, #ffccd5)', // Lighter and whiter gradient
              WebkitBackgroundClip: 'text', // Clip gradient to text
              WebkitTextFillColor: 'transparent', // Transparent fill to show gradient
              textTransform: 'uppercase', // Uppercase text
              lineHeight: 1.2, // Adjust line height
              backgroundSize: '200% 200%', // Double the background size for animation
              animation: `${gradientAnimation} 5s linear infinite`, // Apply animation
            }}
          >
            SATIT CONNECT
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export { TOP_BAR_HEIGHT }; // Export the height for reuse
export default TopBar;