// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';

// Import Styles
import './App.css';
import theme from './themes';

// Import essential components
import BottomBar, { BOTTOM_BAR_HEIGHT } from './components/generic/BottomBar';
import TopBar, { TOP_BAR_HEIGHT } from './components/generic/TopBar';

// Import notification worker
import NotificationRegisterWorker from './components/generic/NotificationRegisterWorker';

// Import notification popup
import NotificationPopup from './components/generic/NotificationPopup';

// Import pages
import LoginPage from './pages/loginPage';
import DashboardPage from './pages/dashboardPage';
import MiscPage from './pages/miscPage';
import NotificationManagementPage from './pages/notificationManagementPage';
import NotificationCategoryPage from './pages/notificationCategoryPage';
import DirectoryPage from './pages/directoryPage';
import MyParentPage from './pages/myParentPage';
import MyChildrenPage from './pages/myChildrenPage';
import AboutPage from './pages/aboutPage';
import NotificationsPage from './pages/notificationsPage';
import MyAccountPage from './pages/myAccountPage';
import QRScannerPage from './pages/qrScannerPage';
import FilesManagementPage from './pages/filesManagementPage';
import VerifyEmailPage from './pages/verifyEmailPage';

// Import Translation
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
  const location = useLocation();
  const hideTopBarPaths = ['/', '/login'];
  const hideBottomBarPaths = ['/', '/login'];
  const hideNotificationSnackbarPaths = ['/', '/login'];
  const hideNotificationPopupPaths = ['/', '/login', '/notifications'];

  const hidePaths = ['/verify-email/'];

  const shouldHide = (pathname) => hidePaths.some((path) => pathname.startsWith(path));

  const showTopBar = !hideTopBarPaths.includes(location.pathname) && !shouldHide(location.pathname);
  const showBottomBar = !hideBottomBarPaths.includes(location.pathname) && !shouldHide(location.pathname);
  const showNotificationSnackbar = !hideNotificationSnackbarPaths.includes(location.pathname) && !shouldHide(location.pathname);
  const showNotificationPopup = !hideNotificationPopupPaths.includes(location.pathname) && !shouldHide(location.pathname);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh', // Changed from minHeight to height
            boxSizing: 'border-box', // Ensure padding is included in height
          }}
        >
          {showNotificationSnackbar && <NotificationRegisterWorker />}
          {showNotificationPopup && <NotificationPopup />}
          {showTopBar && <TopBar />}
          <Box
            component="main"

            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingTop: showTopBar ? `${TOP_BAR_HEIGHT}px` : '0px',
              paddingBottom: showBottomBar ? `${BOTTOM_BAR_HEIGHT}px` : '0px',
              boxSizing: 'border-box', // Include padding in size calculations
            }}
          >
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route path="/notifications/:id" element={<NotificationsPage />} />
              <Route path="/misc" element={<MiscPage />} />
              <Route path="/notificationsadmin/manage" element={<NotificationManagementPage />} />
              <Route path="/notificationsadmin/categories" element={<NotificationCategoryPage />} />
              <Route path="/directory" element={<DirectoryPage />} />
              <Route path="/relation/my-children" element={<MyChildrenPage />} />
              <Route path="/relation/my-parents" element={<MyParentPage />} />
              <Route path="/qrscanner" element={<QRScannerPage />} />
              <Route path="/files" element={<FilesManagementPage />} />
              <Route path="/profile" element={<MyAccountPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </Box>
          {showBottomBar && <BottomBar />}
        </Box>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;