import { API_URL } from "../config";
import axios from "axios";

const CACHE_KEY = 'psRelations';
const CACHE_TIMESTAMP_KEY = 'psRelationsTimestamp';
const CACHE_DURATION = 0; // 0 ms = no cache

async function getParents() {
    const cachedParentRelations = localStorage.getItem(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);

    if (cachedParentRelations && cachedTimestamp) {
        const age = Date.now() - parseInt(cachedTimestamp, 10);
        if (age < CACHE_DURATION) {
            return JSON.parse(cachedParentRelations);
        }
    }

    try {
        const response = await axios.get(`${API_URL}/api/psrelation/get_parents`);
        const parentRelations = response.data;
        localStorage.setItem(CACHE_KEY, JSON.stringify(parentRelations));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
        return parentRelations;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

async function getStudents() {
    const cachedParentRelations = localStorage.getItem(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);

    if (cachedParentRelations && cachedTimestamp) {
        const age = Date.now() - parseInt(cachedTimestamp, 10);
        if (age < CACHE_DURATION) {
            return JSON.parse(cachedParentRelations);
        }
    }

    try {
        const response = await axios.get(`${API_URL}/api/psrelation/get_students`);
        const parentRelations = response.data;
        localStorage.setItem(CACHE_KEY, JSON.stringify(parentRelations));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
        return parentRelations;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

async function bindStudent(code) {
    try {
        const response = await axios.post(`${API_URL}/api/psrelation/bind_student?code=${code}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

async function unbindStudent(student) {
    try {
        const response = await axios.post(`${API_URL}/api/psrelation/unbind_student?student=${student}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

async function generatePairingCode() {
    try {
        const response = await axios.post(`${API_URL}/api/psrelation/generate_pairing_code`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error so it can be handled by the calling code
    }
}

export default {
    getParents,
    getStudents,
    bindStudent,
    unbindStudent,
    generatePairingCode,
};