// src/components/dashboardPage/QRCodeID.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import qridService from '../../services/qridService';
import { QRCodeCanvas } from 'qrcode.react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
    Divider,
    Dialog,
    IconButton,
    Backdrop,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 500,
    width: '100%',
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer', // Indicates that the card is clickable
    userSelect: 'none', // Prevents text selection on tap
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:active': {
        transform: 'scale(0.98)', // Provides tactile feedback on tap
        boxShadow: theme.shadows[4],
    },
    '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`, // Accessibility focus
    },
}));

const QRCodeWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const UserDetails = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 8, // Small padding for content spacing
});

const ExpirationBox = styled(Box)({
    textAlign: 'center',
    marginTop: 8, // Small spacing
});

const FullScreenModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(4),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(2),
    zIndex: 1,
    color: theme.palette.grey[500],
}));

const QRCodeID = ({ userInfo, userType }) => {
    const [base64ID, setBase64ID] = useState('');
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [open, setOpen] = useState(false); // State to control modal
    const navigate = useNavigate();

    useEffect(() => {
        let interval = null;

        async function generateQRid() {
            try {
                const base64ID = await qridService.generateQRid();
                setBase64ID(base64ID);
                updateSecondsLeft(base64ID);
            } catch (error) {
                console.error('Error generating QR ID:', error);
                navigate('/dashboard');
            }
        }

        function decodeBase64(base64) {
            try {
                const jsonPayload = atob(base64);
                return JSON.parse(jsonPayload);
            } catch (error) {
                console.error('Error decoding Base64:', error);
                return null;
            }
        }

        function checkExpiration(base64ID) {
            const decoded = decodeBase64(base64ID);
            if (!decoded || !decoded.exp) return false;
            const currentTime = Math.floor(Date.now() / 1000);
            return decoded.exp > currentTime;
        }

        function updateSecondsLeft(base64ID) {
            const decoded = decodeBase64(base64ID);
            if (!decoded || !decoded.exp) {
                setSecondsLeft(0);
                return;
            }
            const currentTime = Math.floor(Date.now() / 1000);
            setSecondsLeft(decoded.exp - currentTime);
        }

        async function handleQRid() {
            if (!base64ID || !checkExpiration(base64ID)) {
                await generateQRid();
            } else {
                updateSecondsLeft(base64ID);
            }
        }

        handleQRid();

        interval = setInterval(() => {
            if (secondsLeft <= 0) {
                handleQRid();
            } else {
                setSecondsLeft(prev => prev - 1);
            }
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [base64ID, navigate, secondsLeft]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <StyledCard onClick={handleOpen} tabIndex={0} onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    handleOpen();
                }
            }}>
                <CardContent sx={{ padding: '16px 25px 16px 25px' }}> {/* Tighter padding */}
                    <Grid container spacing={1} alignItems="center">
                        {/* QR Code Section */}
                        <Grid item xs={4}>
                            <QRCodeWrapper>
                                {base64ID ? (
                                    <QRCodeCanvas value={base64ID} size={80} />
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        Loading QR Code...
                                    </Typography>
                                )}
                            </QRCodeWrapper>
                        </Grid>
                        {/* User Details Section */}
                        <Grid item xs={8}>
                            <UserDetails>
                                <Typography variant="h6" component="div">
                                    {userInfo.firstName} {userInfo.lastName}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {userType}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {userInfo.upn}
                                </Typography>
                            </UserDetails>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                {/* Expiration Message */}
                <ExpirationBox>
                    <Typography variant="caption" color={secondsLeft > 0 ? 'textPrimary' : 'error'}>
                        {secondsLeft > 0
                            ? `Expires in ${secondsLeft} seconds`
                            : 'Expired'}
                    </Typography>
                </ExpirationBox>
            </StyledCard>

            {/* Full-Screen Modal */}
            <FullScreenModal
                fullScreen
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <CloseButton onClick={handleClose} aria-label="close">
                    <CloseIcon fontSize="large" />
                </CloseButton>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    px={2} // Padding for smaller screens
                >
                    <Typography variant="h5" gutterBottom>
                        Your QR Code
                    </Typography>
                    {base64ID ? (
                        <QRCodeCanvas value={base64ID} size={256} />
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            Loading QR Code...
                        </Typography>
                    )}
                    <Typography
                        variant="h6"
                        color={secondsLeft > 0 ? 'textPrimary' : 'error'}
                        sx={{ mt: 2 }}
                        align="center"
                    >
                        {secondsLeft > 0
                            ? `Expires in ${secondsLeft} seconds`
                            : 'Expired'}
                    </Typography>
                </Box>
            </FullScreenModal>
        </>
    );
};

export default QRCodeID;
