// src/pages/DashboardPage.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authenticationService from '../services/authenticationService';
import QRCodeID from '../components/dashboardPage/QRCodeID';
import { Box, Typography, Container } from '@mui/material';
import { BOTTOM_BAR_HEIGHT } from '../components/generic/BottomBar';
import { USER_TYPE } from '../services/directoryService';
import ApplicationGrid from '../components/dashboardPage/ApplicationGrid';

const DashboardPage = () => {
    const [userInfo, setUserInfo] = useState({});
    const [userType, setUserType] = useState('Unknown');
    const navigate = useNavigate();

    // Redirect to login if not authenticated
    useEffect(() => {
        authenticationService.isLoggedIn().then(isLoggedIn => {
            if (!isLoggedIn) {
                navigate('/login');
            }
        });
    }, [navigate]);

    // Fetch user information
    useEffect(() => {
        authenticationService.getUserInfo().then(data => {
            setUserInfo(data);
            switch (data.userType) {
                case USER_TYPE.STUDENT:
                    setUserType('Student');
                    break;
                case USER_TYPE.PARENT:
                    setUserType('Parent');
                    break;
                case USER_TYPE.STAFF:
                    setUserType('Staff');
                    break;
                case USER_TYPE.TEACHER:
                    setUserType('Teacher');
                    break;
                default:
                    setUserType('Associated External User');
            }
        });
    }, []);

    // Log userInfo changes
    useEffect(() => {
        console.log('User Info:', userInfo);
    }, [userInfo]);

    return (
        <Container
            maxWidth="md"
            disableGutters
            sx={{
                paddingBottom: `${BOTTOM_BAR_HEIGHT + 16}px`,
                overflowY: 'auto',
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={4}
            >
                <Typography variant="h5">
                    Welcome to SATIT CONNECT
                </Typography>
                <Box mt={4} width="100%" display="flex" justifyContent="center">
                    <QRCodeID userInfo={userInfo} userType={userType} />
                </Box>
                <ApplicationGrid />
            </Box>
        </Container>
    );
};

export default DashboardPage;
