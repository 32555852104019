// src/pages/filesManagementPage.js

import React, { useState, useEffect } from 'react';
import { 
    Paper, 
    useMediaQuery // Added useMediaQuery
} from '@mui/material';
import FileManagementToolbar from '../components/fileManagement/FileManagementToolbar';
import FileTreeView from '../components/fileManagement/FileTreeView';
import FileSelectionPopup from '../components/fileManagement/FileSelectionPopup';
import { uploadFile, deleteFile, listFiles, downloadFile } from '../services/storageService';
import { Add, Delete, GetApp, Folder, InsertDriveFile } from '@mui/icons-material';

export default function FilesManagementPage() {
    const [treeItems, setTreeItems] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        fetchFiles();
    }, []);

    // Log selected item changes
    useEffect(() => {
        console.log('Selected Files:', selectedFiles);
    }, [selectedFiles]);

    const fetchFiles = async () => {
        try {
            const data = await listFiles();
            if (data) {
                const items = buildTreeItems(data);
                setTreeItems(items);
            } else {
                console.error('No data received from listFiles.');
            }
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const buildTreeItems = (treeData) => {
        const traverse = (nodes) =>
            Object.values(nodes).map((node) => ({
                id: node.path,
                label: node.name,
                icon: node.isFile ? <InsertDriveFile /> : <Folder />,
                children: Object.keys(node.children).length > 0 ? traverse(node.children) : [],
                isFile: node.isFile,
            }));
        return traverse(treeData);
    };

    // Helper function to split fullPath into path and filename
    const splitPath = (fullPath) => {
        const parts = fullPath.split('/');
        const filename = parts.pop();
        const path = parts.join('/');
        return { path: path || '', filename };
    };

    const handleDeleteClick = async () => { // Modified to delete files or folders
        try {
            for (const item of selectedFiles) {
                const { path, filename } = splitPath(item.path);
                if (item.isFile) {
                    await deleteFile(filename, path);
                } else {
                    await deleteFolderRecursively(path);
                }
            }
            setSelectedFiles([]);
            fetchFiles();
        } catch (error) {
            console.error('Error deleting items:', error);
        }
    };

    const handleDownloadClick = async () => { // Modified to download only single file
        if (selectedFiles.length !== 1 || !selectedFiles[0].isFile) {
            alert('Please select a single file to download.');
            return;
        }
        try {
            const fullPath = selectedFiles[0].path;
            const { path, filename } = splitPath(fullPath);
            const file = await downloadFile(filename, path);
            // Handle file download
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleUpload = async (event) => { // Modified to upload to selected folder
        try {
            const file = event.target.files[0];
            if (!file) return;
            let folderPath = '';
            if (selectedFiles.length === 1 && !selectedFiles[0].isFile) {
                folderPath = selectedFiles[0].path;
            } else {
                folderPath = prompt('Enter folder path (leave blank for root):');
            }
            const fullPath = folderPath ? `${folderPath}/${file.name}` : file.name;
            const { path, filename } = splitPath(fullPath);
            await uploadFile(file, filename, path);
            fetchFiles();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleSelectedItemsChange = (event, itemIds) => { // Updated selection handler
        const updatedSelection = itemIds.map(id => {
            const item = findItemById(id, treeItems);
            return { path: item.id, isFile: item.isFile };
        });
        setSelectedFiles(updatedSelection);
    };

    // Helper to find item by ID
    const findItemById = (id, items) => {
        for (const item of items) {
            if (item.id === id) return item;
            if (item.children) {
                const found = findItemById(id, item.children);
                if (found) return found;
            }
        }
        return null;
    };

    // Helper to delete folder recursively
    const deleteFolderRecursively = async (folderPath) => {
        // Implement logic to delete all files within the folder
        // This might involve listing all files in the folder and deleting them one by one
        const data = await listFiles(folderPath);
        if (data) {
            for (const file of data.files) {
                await deleteFile(file.name, folderPath);
            }
        }
        // Optionally delete the folder itself if applicable
    };

    const handleUploadClick = () => {
        document.getElementById('upload-input').click();
    };

    const handleFileSelect = (file) => {
        console.log('Selected file:', file);
        // Additional logic for selected file...
    };

    return (
        <Paper sx={{ position: 'relative', minHeight: '100vh', padding: 2 }}>
            <FileManagementToolbar
                selectedFiles={selectedFiles}
                onUploadClick={handleUploadClick}
                onDeleteClick={handleDeleteClick}
                onDownloadClick={handleDownloadClick}
            />
            <FileTreeView
                treeItems={treeItems}
                selectedFiles={selectedFiles}
                onSelectedItemsChange={handleSelectedItemsChange}
            />
            <input id="upload-input" type="file" hidden onChange={handleUpload} />
            <FileSelectionPopup
                open={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSelect={handleFileSelect}
            />
        </Paper>
    );
}