// src/components/generic/NotificationPopup.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  Slide
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useFCMListener from '../../hooks/useFCMListener';
import nativeService from '../../services/nativeService';

// Slide transition for Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Styled Components
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  justifyContent: 'flex-end',
}));

const NotificationPopup = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    id: '',
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useFCMListener((payload) => {
    if (payload.data.type !== 'general') {
      return;
    }
    nativeService.notificationSound();
    nativeService.vibrate();
    setNotification({
      title: payload.notification.title,
      body: payload.notification.body,
      id: payload.data.id,
    });
    setOpen(true);
    console.log('Message received:', payload);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleReadMore = () => {
    navigate(`/notifications/${notification.id}`);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      aria-labelledby="notification-dialog-title"
    >
      <StyledDialogTitle id="notification-dialog-title">
        {notification.title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: theme.palette.primary.contrastText,
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Typography variant="body1">{notification.body}</Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={handleReadMore}
          color="primary"
          variant="contained"
        >
          Read More
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default NotificationPopup;
