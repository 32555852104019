
import React from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';

const UserListItem = ({ user, onClick, isLast }) => (
  <div>
    <ListItem button onClick={() => onClick(user)}>
      <ListItemAvatar>
        <Avatar>
          {user.givenName ? user.givenName.charAt(0).toUpperCase() :
           user.sn ? user.sn.charAt(0).toUpperCase() :
           user.upn ? user.upn.charAt(0).toUpperCase() : '?'}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${user.givenName || ''} ${user.sn || ''}`.trim() || user.upn || 'Unknown'}
        secondary={user.userPrincipalName || user.upn || ''}
        primaryTypographyProps={{ fontWeight: 500 }}
      />
    </ListItem>
    {!isLast && <Divider variant="inset" />}
  </div>
);

export default UserListItem;