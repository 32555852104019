// src/components/dashboardPage/NotificationList.js

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Chip } from '@mui/material';
import NotificationPreview from './NotificationPreview';

function NotificationList({ notifications }) {
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);

    const handleNotificationClick = (notificationId) => {
        setSelectedNotificationId(notificationId);
    };

    const handleClosePreview = () => {
        setSelectedNotificationId(null);
    };

    useEffect(() => {
        console.log(notifications);
    }, [notifications]);

    return (
        <Box p={2}>
            {Array.isArray(notifications) && notifications.map((notification) => (
                <Box key={notification.id} mb={2}>
                    <Card
                        sx={{
                            cursor: 'pointer',
                            boxShadow: 3,
                            border: '1px solid #e0e0e0',
                            '&:hover': {
                                boxShadow: 6,
                            },
                        }}
                        onClick={() => handleNotificationClick(notification.id)}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '150px', // Adjust as needed
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                <Typography variant="h6">{notification.title}</Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    {notification.short_message}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="caption" color="textSecondary">
                                    {new Date(notification.timestamp).toLocaleString('en-US', {
                                        day: '2-digit',
                                        month: 'long',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                    })}
                                </Typography>
                                <Chip label={notification.category_info.name} color="primary" />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            ))}
            <NotificationPreview
                open={selectedNotificationId !== null}
                handleClose={handleClosePreview}
                notificationId={selectedNotificationId}
            />
        </Box>
    );
}

export default NotificationList;