import React from 'react';
import { Paper, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, Delete, GetApp } from '@mui/icons-material';

export default function FileManagementToolbar({
  selectedFiles,
  onUploadClick,
  onDeleteClick,
  onDownloadClick,
}) {
  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 1,
        marginBottom: 2,
        gap: 1,
      }}
      elevation={3}
    >
      <Typography variant="h5" component="div" pl={3} sx={{ flexGrow: 1 }}>
        Files Management
      </Typography>
      <Tooltip title="Upload File">
        <IconButton
          color="primary"
          aria-label="upload"
          onClick={onUploadClick}
        >
          <Add />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Selected Files">
        <span>
          <IconButton
            color="error"
            aria-label="delete"
            onClick={onDeleteClick}
            disabled={selectedFiles.length === 0}
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Download Selected File">
        <span>
          <IconButton
            color="primary"
            aria-label="download"
            onClick={onDownloadClick}
            disabled={selectedFiles.length !== 1}
          >
            <GetApp />
          </IconButton>
        </span>
      </Tooltip>
    </Paper>
  );
}
